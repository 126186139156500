import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {landEntitiesAdapter} from '../adapters/land-entities.adapters';


export class LandEntitiesSelectors {

  private static adapterSelectors = landEntitiesAdapter.getSelectors();

  private static select = {
    entities: (state: AppState) => state.landEntities,
    isLoaded: (state: AppState) => state.landEntities.laenderLoaded,
  };

  public static all = createSelector(
    this.select.entities,
    this.adapterSelectors.selectAll,
  );

  public static laenderLoaded = createSelector(
    this.select.isLoaded,
    identity,
  );

}
