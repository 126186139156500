import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterBarComponent} from './filter-bar.component';
import {ButtonModule, FormFieldTextComponent, IconModule} from '@adnova/jf-ng-components';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    FilterBarComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    FormsModule,
    FormFieldTextComponent,
  ],
  exports: [
    FilterBarComponent,
  ]
})
export class FilterBarModule {
}
