import {PositionDTO} from '../../openapi/fakturierung-openapi';


export const positionenMock: PositionDTO[] = [
  {
    nummer: 1,
    produktId: '1',
    anzahl: 1,
    betragssumme: 500,
  },
  {
    nummer: 2,
    produktId: '3',
    anzahl: 1,
    betragssumme: 1500.58,
  },
  {
    nummer: 3,
    produktId: '3',
    anzahl: 1,
    positionsbeschreibung: 'Das ist eine individuelle Beschreibung für das selbe Produkt, wie das darüberliegende.',
    betragssumme: 1500,
  },
  {
    nummer: 4,
    produktId: '4',
    anzahl: 2,
    betragssumme: 234234,
  },
  {
    nummer: 5,
    produktId: '999',
    anzahl: 4,
    betragssumme: 500,
  },
];
