import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchluesseldatenService} from '../../openapi/schluesseldaten-openapi';
import {LandEntitiesActions} from '../actions/land-entities.actions';
import {concatMap, of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {SentryActions} from '../actions/sentry.actions';


@Injectable()
export class LandEntitiesEffects {

  constructor(
    private actions$: Actions,
    private schluesseldatenService: SchluesseldatenService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  /**
   * Lädt die Länder vom Service.
   */
  readonly readLaender$ = createEffect(
    () => this.actions$.pipe(
      ofType(LandEntitiesActions.readLaender),
      switchMap(() => {
        return this.schluesseldatenService.readLaender().pipe(
          map(landDtos => {
            this.logger.debug(
              'read laender succeeded.',
            );

            return LandEntitiesActions.readLaenderSuccess({
              landDtos,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'read laender failed. error: ',
                error,
              );

              return [
                LandEntitiesActions.readLaenderFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden der Länder.
   */
  readonly readLaenderFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(LandEntitiesActions.readLaenderFailure),
      map(({error}) => {
        this.snackbar.open(
          'Fehler bei dem Laden der Länder-Informationen. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

}
