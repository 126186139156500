import {createAction, props} from '@ngrx/store';
import {EinheitDTO, ProduktDTO, UstProzentsatzDTO} from '../../openapi/fakturierung-openapi';
import {DeepPartial} from '../../types/deep-partial';


export class ProduktDialogActions {

  private static readonly prefix = '[Produkt Dialog] ';

  public static open = createAction(
    this.prefix + 'Open produkt dialog.',
    props<{
      produkt?: ProduktDTO,
      addToInvoice?: boolean,
    }>(),
  );

  public static changeProdukt = createAction(
    this.prefix + 'Change produkt.',
    props<{ produkt: DeepPartial<ProduktDTO> }>(),
  );

  public static close = createAction(
    this.prefix + 'Close produkt dialog.',
  );

  public static readAvailableEinheiten = createAction(
    this.prefix + 'Read available einheiten.',
  );

  public static readAvailableEinheitenSuccess = createAction(
    '[Create Produkt Dialog] Read available einheiten successfully.',
    props<{
      einheiten: EinheitDTO[];
    }>(),
  );

  public static readAvailableEinheitenFailure = createAction(
    '[Create Produkt Dialog] Read available einheiten failed.',
    props<{
      error: any;
    }>(),
  );

  public static readUmsatzsteuersaetze = createAction(
    '[Create Produkt Dialog] Read available Ust-Sätze.',
  );

  public static readUmsatzsteuersaetzeSuccess = createAction(
    '[Create Produkt Dialog] Read available Ust-Sätze successfully.',
    props<{
      umsatzsteuersaetze: UstProzentsatzDTO[];
    }>(),
  );

  public static readUmsatzsteuersaetzeFailure = createAction(
    '[Create Produkt Dialog] Read available Ust-Sätze failed.',
    props<{
      error: any;
    }>(),
  );

  public static assignNextProduktnummer = createAction(
    this.prefix + 'Assign next produktnummer.',
    props<{ nummer: number }>(),
  );
}
