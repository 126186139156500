import {createAction, props} from '@ngrx/store';


export class DeleteKundeDialogActions {

    public static open = createAction(
      '[Kunde Table] Open delete kunde dialog.',
      props<{
        kundeId: string;
      }>(),
    );

    public static close = createAction(
      '[Delete Kunde Dialog] Close delete kunde dialog.',
    );
}
