import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../states/app.state';
import {
  fakturierungsbelegEntitiesReducer,
  initialFakturierungsbelegEntitiesState
} from './fakturierungsbeleg-entities.reducers';
import {kundeEntitiesReducer, initialKundeEntitiesState} from './kunde-entities.reducers';
import {initialProduktEntitiesState, produktEntitiesReducer} from './produkt-entities.reducers';
import {fakturierungsbelegTableReducer, initialFakturierungsbelegTableState} from './fakturierungsbeleg-table.reducer';
import {
  deleteFakturierungsbelegDialogReducer,
  initialDeleteFakturierungsbelegDialogState
} from './delete-fakturierungsbeleg-dialog.reducers';
import {deleteAbsenderDialogReducer, initialDeleteKundeDialogState} from './delete-kunde-dialog.reducers';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';
import {belegeEmptyStateDialogReducer, initialBelegeEmptyStateDialogState} from './belege-empty-state-dialog.reducers';
import {kundeTableReducer, initialKundeTableState} from './kunde-table.reducer';
import {initialProduktTableState, produktTableReducer} from './produkt-table.reducer';
import {deleteProduktDialogReducer, initialDeleteProduktDialogState} from './delete-produkt-dialog.reducers';
import {initialUploadLogoDialogState, uploadLogoDialogReducer} from './upload-logo-dialog.reducers';
import {produktDialogReducer, initialProduktDialogState} from './produkt-dialog.reducer';
import {kundeDialogReducer, initialKundeDialogState} from './kunde-dialog.reducers';
import {
  kundeLeistungsempfaengerReducer,
  initialKundeLeistungsempfaengerState
} from './kunde-leistungsempfaenger.reducers';
import {fakturierungsbelegFormReducer, initialFakturierungsbelegFormState} from './fakturierungsbeleg-form.reducers';
import {addPositionReducer, initialAddPositionState} from './add-position.reducers';
import {initialLandEntitiesState, landEntitiesReducer} from './land-entities.reducers';
import {
  initialUmsatzsteuerschluesselEntitiesState,
  umsatzsteuerschluesselEntitiesReducer
} from './umsatzsteuerschluessel-entities.reducers';
import {initialMengeneinheitEntitiesState, mengeneinheitEntitiesReducer} from './mengeneinheit-entities.reducers';


export const initialAppState: AppState = {
  ...initialJfLibState,
  fakturierungsbelegEntities: initialFakturierungsbelegEntitiesState,
  fakturierungsbelegTable: initialFakturierungsbelegTableState,
  kundeEntities: initialKundeEntitiesState,
  kundenTable: initialKundeTableState,
  kundeLeistungsempfaenger: initialKundeLeistungsempfaengerState,
  addPosition: initialAddPositionState,
  produktEntities: initialProduktEntitiesState,
  produktTable: initialProduktTableState,
  produktDialog: initialProduktDialogState,
  deleteFakturierungsbelegDialog: initialDeleteFakturierungsbelegDialogState,
  uploadLogoDialog: initialUploadLogoDialogState,
  kundenDialog: initialKundeDialogState,
  deleteKundeDialog: initialDeleteKundeDialogState,
  deleteProduktDialog: initialDeleteProduktDialogState,
  belegeEmptyStateDialog: initialBelegeEmptyStateDialogState,
  fakturierungsbelegForm: initialFakturierungsbelegFormState,
  landEntities: initialLandEntitiesState,
  umsatzsteuerschluesselEntities: initialUmsatzsteuerschluesselEntitiesState,
  mengeneinheitEntities: initialMengeneinheitEntitiesState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
  fakturierungsbelegEntities: fakturierungsbelegEntitiesReducer,
  fakturierungsbelegTable: fakturierungsbelegTableReducer,
  kundeEntities: kundeEntitiesReducer,
  kundenTable: kundeTableReducer,
  kundeLeistungsempfaenger: kundeLeistungsempfaengerReducer,
  addPosition: addPositionReducer,
  produktEntities: produktEntitiesReducer,
  produktTable: produktTableReducer,
  produktDialog: produktDialogReducer,
  deleteFakturierungsbelegDialog: deleteFakturierungsbelegDialogReducer,
  uploadLogoDialog: uploadLogoDialogReducer,
  kundenDialog: kundeDialogReducer,
  deleteKundeDialog: deleteAbsenderDialogReducer,
  deleteProduktDialog: deleteProduktDialogReducer,
  belegeEmptyStateDialog: belegeEmptyStateDialogReducer,
  fakturierungsbelegForm: fakturierungsbelegFormReducer,
  landEntities: landEntitiesReducer,
  umsatzsteuerschluesselEntities: umsatzsteuerschluesselEntitiesReducer,
  mengeneinheitEntities: mengeneinheitEntitiesReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
