<jf-app-skeleton [sidebar]="true" [contentBorder]="true">
  <jf-betriebsauswahl
    betriebsauswahl
    [impressumLink]="'/legal/impressum'"
    [badgeCounts]="badgeCounts"
  ></jf-betriebsauswahl>

  <ng-container content>
    <router-outlet *ngIf="!showError"></router-outlet>
    <jf-betrieb-not-found *ngIf="showError"></jf-betrieb-not-found>
  </ng-container>
</jf-app-skeleton>
