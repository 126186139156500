import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {ProduktDTO} from '../../openapi/fakturierung-openapi';
import {produktEntitiesAdapter} from '../adapters/produkt-entities.adapter';
import {identity} from 'rxjs';


export class ProduktEntitiesSelectors {

  private static adapterSelectors = produktEntitiesAdapter.getSelectors();

  private static select = {
    produktEntities: (state: AppState) => state.produktEntities,
    totalElements: (state: AppState) => state.produktEntities.totalElements,
    createSaveActionSuccessful: (state: AppState) => state.produktEntities.createSaveActionSuccessful,
    deleteActionSuccessful: (state: AppState) => state.produktEntities.deleteActionSuccessful,
    nextProduktnummern: (state: AppState) => state.produktEntities.nextProduktnummern,
  };

  public static all = createSelector(
    this.select.produktEntities,
    this.adapterSelectors.selectAll,
  );

  public static produktById = (produktId: string) => createSelector(
    this.all,
    produktList => produktList.find(produkt => {
      return produkt.id === produktId;
    }),
  );

  public static produkteByIds = (produktIds: string[]): MemoizedSelector<AppState, ProduktDTO[]> => createSelector(
    this.all,
    produktDtos => {
      const produktMap = new Map(produktDtos.map(produktDto => [produktDto.id, produktDto]));
      return produktIds
        .map(id => produktMap.get(id))
        .filter((produktDto): produktDto is ProduktDTO => !!produktDto);
    }
  );

  public static produkteByBetriebId = (betriebId: string) => createSelector(
    this.all,
    produktList => produktList.filter(produkt => produkt && produkt.betriebId === betriebId),
  );

  public static totalElements = createSelector(
    this.select.totalElements,
    identity,
  );

  public static createSaveActionSuccessful = createSelector(
    this.select.createSaveActionSuccessful,
    identity,
  );

  public static deleteActionSuccessful = createSelector(
    this.select.deleteActionSuccessful,
    identity,
  );

  public static nextProduktnummerByBetriebId = (betriebId: string) => createSelector(
    this.select.nextProduktnummern,
    nextProduktnummern => nextProduktnummern?.find(nextProduktnummer => nextProduktnummer.betriebId === betriebId)?.nummer,
  );

}
