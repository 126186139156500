<div class="dialog-wrapper">

  <h1
    class="dialog-h1"
    i18n
  >
    Produkt löschen
  </h1>

  <div
    class="beleg-data"
    i18n
  >
    <div *ngIf="produktDto?.nummer">
      {{ produktDto?.nummer }}
    </div>

    <div>
      {{ produktDto?.bezeichnung }}
    </div>

    <div *ngIf="produktDto?.beschreibung">
      {{ produktDto?.beschreibung }}
    </div>

    <div *ngIf="prozentsatz">
      {{ prozentsatz | percent }} USt
    </div>

    <div class="hint">Ein gelöschtes Produkt kann nicht wiederhergestellt werden.</div>
  </div>

  <mat-divider></mat-divider>

  <div class="button-wrapper">

    <jf-button
      [variant]="'primary'"
      (doClick)="deleteProduktClick()"
      i18n
    >
      Löschen
    </jf-button>

    <jf-button
      [variant]="'ghost'"
      (doClick)="closeDialogClick()"
      i18n
    >
      Abbrechen
    </jf-button>
  </div>
</div>
