import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {UmsatzsteuerschluesselExtended} from '../../interfaces/umsatzsteuerschluessel-extended.interface';


export class UmsatzsteuerschluesselEntitiesActions {

  private static readonly prefix = '[Umsatzsteuerschluessel Entities] ';

  public static readUmsatzsteuerschluessel = createAction(
    this.prefix + 'Read list of umsatzsteuerschluessel.',
    props<{
      betriebId: string,
    }>(),
  );

  public static readUmsatzsteuerschluesselSuccess = createAction(
    this.prefix + 'Read list of umsatzsteuerschluessel successfully.',
    props<{
      betriebId: string,
      umsatzsteuerschluesselDtos: UmsatzsteuerschluesselExtended[],
    }>(),
  );

  public static readUmsatzsteuerschluesselFailed = createAction(
    this.prefix + 'Read list of umsatzsteuerschluessel failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

}
