import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {KundeEntitiesSelectors} from '../../../store/selectors/kunde-entities.selectors';
import {
  DeleteKundeDialogSelectors
} from '../../../store/selectors/delete-kunde-dialog.selectors';
import {take} from 'rxjs';
import {KundeDTO} from '../../../openapi/fakturierung-openapi';
import {DeleteKundeDialogActions} from '../../../store/actions/delete-kunde-dialog.actions';
import {NGXLogger} from 'ngx-logger';
import {KundeKontaktdaten} from '../../../interfaces/kunde-kontaktdaten.interface';
import {KundeKontaktdatenUtilService} from '../../../services/kunde-kontaktdaten-util.service';
import {KundeEntitiesActions} from '../../../store/actions/kunde-entities.actions';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-delete-kunde-dialog',
  templateUrl: './delete-kunde-dialog.component.html',
  styleUrls: ['./delete-kunde-dialog.component.scss']
})
export class DeleteKundeDialogComponent implements OnInit {

  private _destroyRef = inject(DestroyRef);
  private _store = inject(Store<AppState>);
  private _logger = inject(NGXLogger);
  private _kundeKontaktdatenUtilService = inject(KundeKontaktdatenUtilService);

  private _kundeDto?: KundeDTO;
  private _kundeKontaktDaten?: KundeKontaktdaten;

  get kundeDto(): KundeDTO | undefined {
    return this._kundeDto;
  }

  get kundeKontaktDaten(): KundeKontaktdaten | undefined {
    return this._kundeKontaktDaten;
  }

  ngOnInit() {
    this._store.select(DeleteKundeDialogSelectors.deleteData).pipe(
      take(1),
    ).subscribe(data => {
      if (data.kundeId) {

        this._store.select(KundeEntitiesSelectors.kundenById(
          data.kundeId,
        )).pipe(
          take(1),
        ).subscribe(kundeDto => {
          if (!kundeDto) return;

          this._kundeDto = kundeDto;
          this._kundeKontaktDaten = this._kundeKontaktdatenUtilService.getKundeKontaktdaten(kundeDto);
        });
      } else {
        this._logger.warn('kundeId is not set at delete dialog');
      }
    });

    // INFO: Nach dem Löschen des Kunden Dialog schließen
    this._store.select(KundeEntitiesSelectors.deleteActionSuccessful).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(deleteActionSuccessful => {
      if (!deleteActionSuccessful) return;

      this.doCloseDialog();
    });
  }

  protected doDeleteKunde(): void {
    this._store.dispatch(KundeEntitiesActions.deleteKunde({
      betriebId: this.kundeDto!.betriebId,
      kundeId: this.kundeDto!.id
    }));
  }

  protected doCloseDialog(): void {
    this._store.dispatch(DeleteKundeDialogActions.close());
  }
}
