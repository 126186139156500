import {kundeEntitiesAdapter} from '../adapters/kunde-entities.adapter';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {KundeDTO} from '../../openapi/fakturierung-openapi';


export class KundeEntitiesSelectors {

  private static adapterSelectors = kundeEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    kundenEntities: (state: AppState) => state.kundeEntities,
    totalElements: (state: AppState) => state.kundeEntities.totalElements,
    createSaveActionSuccessful: (state: AppState) => state.kundeEntities.createSaveActionSuccessful,
    deleteActionSuccessful: (state: AppState) => state.kundeEntities.deleteActionSuccessful,
    nextKundennummern: (state: AppState) => state.kundeEntities.nextKundennummern,
  };

  public static all = createSelector(
    this.select.kundenEntities,
    this.adapterSelectors.selectAll,
  );

  public static kundenById = (absenderId: string) => createSelector(
    this.all,
    absenderList => absenderList.find(absender => {
      return absender.id === absenderId;
    }),
  );

  /**
   * Selektor, der Kunden anhand einer Liste von IDs zurückgibt und dabei deren Reihenfolge beibehält.
   *
   * @param {string[]} kundenIds - Ein Array von KundenIds.
   * Die Reihenfolge dieses Arrays bestimmt die Reihenfolge der zurückgegebenen Kunden.
   *
   * @returns {MemoizedSelector<AppState, KundeDTO[]>} Ein Selektor, der ein Array von Kunden zurückgibt, die den
   * angegebenen IDs entsprechen, in der in `kundenIds` angegebenen Reihenfolge für den ausgewählten Betrieb.
   * Gibt ein leeres Array zurück, wenn keine übereinstimmenden Kunden gefunden wurden.
   */
  public static kundenByIds = (kundenIds: string[]): MemoizedSelector<AppState, KundeDTO[]> => createSelector(
    this.all,
    kundenDtos => {
      const kundenMap = new Map(kundenDtos.map(kundenDto => [kundenDto.id, kundenDto]));
      return kundenIds
        .map(id => kundenMap.get(id))
        .filter((kundenDto): kundenDto is KundeDTO => !!kundenDto);
    }
  );

  public static totalElements = createSelector(
    this.select.totalElements,
    identity,
  );

  public static createSaveActionSuccessful = createSelector(
    this.select.createSaveActionSuccessful,
    identity,
  );

  public static deleteActionSuccessful = createSelector(
    this.select.deleteActionSuccessful,
    identity,
  );

  public static nextKundennummerByBetriebId = (betriebId: string) => createSelector(
    this.select.nextKundennummern,
    nextKundennummern => nextKundennummern?.find(nextKundennummer => nextKundennummer.betriebId === betriebId)?.nummer,
  );

}
