import {createAction, props} from '@ngrx/store';
import {ProduktDTO, ProduktPageDTO, ProduktRequestDTO,} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class ProduktEntitiesActions {

  private static readonly prefix = '[Produkt Entities] ';

  public static readProdukteSuccess = createAction(
    this.prefix + 'Read list of produkte successfully.',
    props<{
      produktDtos: ProduktDTO[]
    }>(),
  );

  public static readProdukteFailed = createAction(
    this.prefix + 'Read list of produkte failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countProdukteElementsSuccess = createAction(
    this.prefix + 'Count list of produkte elements successfully.',
    props<{
      totalElements: number,
    }>(),
  );

  public static countProduktePagesSuccess = createAction(
    this.prefix + 'Count list of produkte pages successfully.',
    props<{
      totalPages: number,
    }>(),
  );

  public static loadProduktByIdIfAbsent = createAction(
    this.prefix + 'Load produkt by ID if absent.',
    props<{
      betriebId: string;
      produktId: string;
    }>(),
  );

  public static getProduktById = createAction(
    this.prefix + 'Get produkt by ID.',
    props<{
      betriebId: string;
      produktId: string;
    }>(),
  );

  public static getProduktByIdSuccess = createAction(
    this.prefix + 'Get produkt successfully.',
    props<{
      produktDto: ProduktDTO;
    }>(),
  );

  public static getProduktByIdFailure = createAction(
    this.prefix + 'Get produkt failed.',
    props<{
      error: any;
    }>(),
  );

  public static createProdukt = createAction(
    this.prefix + 'Create produkt.',
    props<{
      betriebId: string,
      requestDto: ProduktRequestDTO,
      addToInvoice?: boolean,
    }>(),
  );

  public static updateProdukt = createAction(
    this.prefix + 'Update produkt.',
    props<{
      betriebId: string,
      produktId: string,
      requestDto: ProduktRequestDTO,
    }>(),
  );

  public static updateProduktSuccess = createAction(
    this.prefix + 'Update produkt successfully.',
    props<{ produktDto: ProduktDTO }>(),
  );

  public static updateProduktFailure = createAction(
    this.prefix + 'Update produkt failed.',
    props<{ error: any }>(),
  );

  public static createProduktSuccess = createAction(
    this.prefix + 'Create produkt successfully.',
    props<{ produktDto: ProduktDTO }>(),
  );

  public static createProduktFailure = createAction(
    this.prefix + 'Create produkt failed.',
    props<{ error: any }>(),
  );

  public static readNextProduktnummer = createAction(
    this.prefix + 'Read next Produktnummer.',
    props<{
      betriebId: string;
    }>(),
  );

  public static readNextProduktnummerSuccess = createAction(
    this.prefix + 'Read next Produktnummer.',
    props<{
      produktnummer: number;
    }>(),
  );

  public static readNextProduktnummerFailure = createAction(
    this.prefix + 'Read next Produktnummer.',
    props<{
      error: any;
    }>(),
  );

  public static deleteProdukt = createAction(
    this.prefix + 'Delete kunde.',
    props<{
      betriebId: string,
      produktId: string,
    }>(),
  );

  public static deleteProduktSuccess = createAction(
    this.prefix + 'Deleted kunde successfully.',
    props<{
      produktId: string,
    }>(),
  );

  public static deleteProduktFailure = createAction(
    this.prefix + 'Delete kunde failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static detectNextProduktnummer = createAction(
    this.prefix + 'Detect next produktnummer.',
    props<{
      betriebId: string,
    }>(),
  );

  public static detectNextProduktnummerSuccess = createAction(
    this.prefix + 'Detected next produktnummer successfully.',
    props<{
      betriebId: string,
      produkt: ProduktPageDTO,
    }>(),
  );

  public static detectNextProduktnummerFailure = createAction(
    this.prefix + 'Detect next produktnummer failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );
}
