import {BadgeValue, InhaberEntitiesSelectors} from '@adnova/jf-ng-components';
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/store/states/app.state';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-betriebsauswahl-page',
  templateUrl: './betriebsauswahl.page.component.html'
})
export class BetriebsauswahlPageComponent implements OnInit {

  private _destroyRef = inject(DestroyRef);
  private _store = inject(Store<AppState>);

  protected badgeCounts: BadgeValue[] = [];

  protected showError: boolean = true;

  ngOnInit(): void {
    this._store.select(InhaberEntitiesSelectors.isCurrentInhaberValid).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe((isCurrentInhaberValid) => {
      this.showError = !isCurrentInhaberValid;
    });
  }
}
