export const mockPdf = `
    %PDF-1.4
    1 0 obj
    << /Type /Catalog /Pages 2 0 R >>
    endobj
    2 0 obj
    << /Type /Pages /Count 1 /Kids [3 0 R] >>
    endobj
    3 0 obj
    << /Type /Page /Parent 2 0 R /MediaBox [0 0 612 792] /Contents 4 0 R >>
    endobj
    4 0 obj
    << /Length 55 >>
    stream
    BT /F1 24 Tf 100 700 Td (Hello, PDF World!) Tj ET
    endstream
    endobj
    xref
    0 5
    0000000000 65535 f
    0000000010 00000 n
    0000000074 00000 n
    0000000178 00000 n
    0000000307 00000 n
    trailer
    << /Root 1 0 R /Size 5 >>
    startxref
    377
    %%EOF
  `;