import {Component, computed, EventEmitter, Input, Output, signal} from '@angular/core';
import {PageableDTO} from '../../../openapi/fakturierung-openapi';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  // Signals for component state
  totalElements$$ = signal(0); // Input decorator will set this directly
  offset$$ = signal(0); // Input decorator will set this directly
  pageSize$$ = signal(0); // Input decorator will set this directly
  pageNumber$$ = signal(0); // Input decorator will set this directly
  totalPages$$ = signal(0); // Input decorator will set this directly

  // Computed properties based on signals
  offsetStart$$ = computed(() => this.offset$$() + 1);
  offsetEnd$$ = computed(() => Math.min(this.offset$$() + this.pageSize$$(), this.totalElements$$()));
  isFirstPage$$ = computed(() => this.pageNumber$$() === 0);
  isLastPage$$ = computed(() => this.pageNumber$$() === this.totalPages$$() - 1);

  // Outputs
  @Output()
  pageableChange = new EventEmitter<PageableDTO>();

  // Input decorators that directly set the signal values
  @Input()
  set totalElements(value: number) {
    this.totalElements$$.set(value);
  }

  @Input()
  set offset(value: number) {
    this.offset$$.set(value);
  }

  @Input()
  set pageSize(value: number) {
    this.pageSize$$.set(value);
  }

  @Input()
  set pageNumber(value: number) {
    this.pageNumber$$.set(value);
  }

  @Input()
  set totalPages(value: number) {
    this.totalPages$$.set(value);
  }

  // Pagination methods to change state and emit changes
  paginateFirst(): void {
    this.pageNumber$$.set(0);
    this.emitPageableChange();
  }

  paginatePrevious(): void {
    this.pageNumber$$.set(this.pageNumber$$() - 1);
    this.emitPageableChange();
  }

  paginateNext(): void {
    this.pageNumber$$.set(this.pageNumber$$() + 1);
    this.emitPageableChange();
  }

  paginateLast(): void {
    this.pageNumber$$.set(this.totalPages$$() - 1);
    this.emitPageableChange();
  }

  private emitPageableChange(): void {
    this.pageableChange.emit({
      number: this.pageNumber$$(),
      size: this.pageSize$$(),
    });
  }
}
