import {DestroyRef, inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthInterceptor as OidcAuthInterceptor} from 'angular-auth-oidc-client';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {AdnovaTokenService} from '../portal/adnova-token.service';
import {Config, ConfigService} from '../config/service/config.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private _destroyRef = inject(DestroyRef);
  private _logger = inject(NGXLogger);
  private _configService = inject(ConfigService);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _adnovaTokenService = inject(AdnovaTokenService);
  private _oidcAuthInterceptor = inject(OidcAuthInterceptor);

  private _config?: Config;

  constructor() {
    this._configService.loaded$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
      ).subscribe(value => {
      this._config = value;
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {

    // INFO: check for intercepting
    const matchesSecureRoots = this.matchesSecureRoots(request.url);
    if (!matchesSecureRoots) {
      return next.handle(request);
    }

    if (this._portalMessageBroker.isRunningInPortal()) {
      // INFO: intercepting running in portal mode
      const token = this._adnovaTokenService.getToken();
      if (!token) {
        this._logger.debug(`Wanted to add token to ${request.url} but found no token: '${token}'`);
        return next.handle(request);
      }

      this._logger.trace(`Using AuthInterceptor in portal mode`);
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
      return next.handle(request);
    }

    // INFO: intercepting running in standalone mode
    this._logger.trace(`Using AuthInterceptor in standalone mode`);
    return this._oidcAuthInterceptor.intercept(request, next);
  }

  private matchesSecureRoots(
    route: string,
  ): boolean {
    const fakturierungConfig = this._config?.fakturierung;

    if (!fakturierungConfig) {
      return false;
    }

    const secureRoutes = [
      fakturierungConfig.apiBaseUrl,
    ];

    for (const configuredRoute of secureRoutes) {
      if (route.startsWith(configuredRoute)) {
        this._logger.trace(`'${route}' matches configured route '${configuredRoute}'`);
        return true;
      }
    }
    this._logger.trace(`Did not find any configured route for route ${route}`);
    return false;
  }
}
