<mat-dialog-content>
  <app-form
    [formInput]="formInputs"
    (cancelClicked)="closeDialogClick()"
  >
    <div *ngIf="!!kundeDto?.id" class="header-content">
      Änderungen an bestehenden Kontaktdaten führen automatisch zur Aktualisierung der zugehörigen
      <b>Rechnungsentwürfe</b>.
    </div>
    <div
      class='formfield-wrapper body-content'>
      <jf-radio-button-group
        [formControl]="formControls.kundendatenType"
        [options]="kundendatenTypes"
        *ngIf="kundendatenTypes"
      ></jf-radio-button-group>

      <app-kunde-geschaeftskunde-form
        *ngIf="selectedAbsenderType && selectedAbsenderType === 'Geschaeftskundendaten'"
        [formControls]="formControls.geschaeft"
        [laender]="laender"
        [anreden]="anreden"
        [kunde]="kundeDto"
        (kundeChanged)="kundeChanged($event)"
      ></app-kunde-geschaeftskunde-form>

      <app-kunde-privatperson-form
        *ngIf="selectedAbsenderType && selectedAbsenderType === 'Privatkundendaten'"
        [formControls]="formControls.privat"
        [laender]="laender"
        [anreden]="anreden"
        [kunde]="kundeDto"
        (kundeChanged)="kundeChanged($event)"
      ></app-kunde-privatperson-form>
    </div>

    <div
      class='footer-content'>
      <jf-button
        (click)="doConfirmClicked()"
        [style]="'primary'"
        [disabled]="!checkFormComplete()"
      >
        {{ primaryButtonLabel }}
      </jf-button>
    </div>
  </app-form>
</mat-dialog-content>
