/**
 * Schluesseldaten
 * Ein API zum Bereitstellen von Schluesseldaten
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AfaArtDTO } from '../model/models';
import { AfaDegressivSdDTO } from '../model/models';
import { AfaLinearSdDTO } from '../model/models';
import { AfaMethodeBetriebswirtschaftlichDTO } from '../model/models';
import { AfaMethodeHandelsrechtlichDTO } from '../model/models';
import { AfaMethodeSdDTO } from '../model/models';
import { AfaMethodeSonderAbschreibungDTO } from '../model/models';
import { AfaMethodeSteuerlichDTO } from '../model/models';
import { AfaMethodeZuschreibungDTO } from '../model/models';
import { AfaNutzungsdauerSdDTO } from '../model/models';
import { AfaTabellenBereichSdDTO } from '../model/models';
import { AfaTabellenGebietSdDTO } from '../model/models';
import { AfaTabellenNummerSdDTO } from '../model/models';
import { AfaTabellenSdDTO } from '../model/models';
import { EinnahmeAusgabeDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { FinanzamtDTO } from '../model/models';
import { KontenAggregierungSdDTO } from '../model/models';
import { KontenAggregierungsartDTO } from '../model/models';
import { KontenrahmenDTO } from '../model/models';
import { KontoSdDTO } from '../model/models';
import { Kostenstelle1SdDTO } from '../model/models';
import { LandDTO } from '../model/models';
import { MengeneinheitSdDTO } from '../model/models';
import { SchluesseldatenBuchhaltungDTO } from '../model/models';
import { SchluesseldatenDTO } from '../model/models';
import { UmsatzsteuerBesteuerungsformDTO } from '../model/models';
import { UmsatzsteuerschluesselSdDTO } from '../model/models';
import { VerwendungsbereichDTO } from '../model/models';
import { WertefeldMengeneinheitSdDTO } from '../model/models';
import { WertefeldSchluesselzahlSdDTO } from '../model/models';
import { WertefeldZuordnungSdDTO } from '../model/models';
import { ZuordnungSdDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SchluesseldatenService {

    protected basePath = 'https://api.adnova.de/schluesseldaten-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Gibt alle AfaDegressivSd zurück
     * Gibt Schluesseldaten AfaDegressivSd zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaDegressivSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaDegressivSdDTO>>;
    public readAfaDegressivSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaDegressivSdDTO>>>;
    public readAfaDegressivSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaDegressivSdDTO>>>;
    public readAfaDegressivSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaDegressivSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaDegressivSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaLinearSd gefiltert nach Kontenrahmen zurück
     * Gibt gefilterte AfaLinearSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaLinearSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaLinearSdDTO>>;
    public readAfaLinearSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaLinearSdDTO>>>;
    public readAfaLinearSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaLinearSdDTO>>>;
    public readAfaLinearSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readAfaLinearSdsByKontenrahmen.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaLinearSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaLinearSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle AfaMethodeSd zurück
     * Gibt Schluesseldaten AfaMethodeSd zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaMethodeSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaMethodeSdDTO>>;
    public readAfaMethodeSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaMethodeSdDTO>>>;
    public readAfaMethodeSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaMethodeSdDTO>>>;
    public readAfaMethodeSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaMethodeSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaMethodeSd gefiltert nach Kontenrahmen zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaMethodeSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaMethodeSdDTO>>;
    public readAfaMethodeSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaMethodeSdDTO>>>;
    public readAfaMethodeSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaMethodeSdDTO>>>;
    public readAfaMethodeSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readAfaMethodeSdsByKontenrahmen.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaMethodeSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaMethodeSd gefiltert nach Kontenrahmen &amp; AfaArt zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param afaArt Die AfaArt auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaMethodeSdsByKontenrahmenAfaArt(kontenrahmen: KontenrahmenDTO, afaArt: AfaArtDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaMethodeSdDTO>>;
    public readAfaMethodeSdsByKontenrahmenAfaArt(kontenrahmen: KontenrahmenDTO, afaArt: AfaArtDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaMethodeSdDTO>>>;
    public readAfaMethodeSdsByKontenrahmenAfaArt(kontenrahmen: KontenrahmenDTO, afaArt: AfaArtDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaMethodeSdDTO>>>;
    public readAfaMethodeSdsByKontenrahmenAfaArt(kontenrahmen: KontenrahmenDTO, afaArt: AfaArtDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readAfaMethodeSdsByKontenrahmenAfaArt.');
        }
        if (afaArt === null || afaArt === undefined) {
            throw new Error('Required parameter afaArt was null or undefined when calling readAfaMethodeSdsByKontenrahmenAfaArt.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaMethodeSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/afaArt/${encodeURIComponent(String(afaArt))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach Kontenrahmen und grouped nach afaSchluessel zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaMethodeSdsByKontenrahmenGroupedByAfaSchluessel(kontenrahmen: KontenrahmenDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<{ [key: string]: Array<AfaMethodeSdDTO>; }>;
    public readAfaMethodeSdsByKontenrahmenGroupedByAfaSchluessel(kontenrahmen: KontenrahmenDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<{ [key: string]: Array<AfaMethodeSdDTO>; }>>;
    public readAfaMethodeSdsByKontenrahmenGroupedByAfaSchluessel(kontenrahmen: KontenrahmenDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<{ [key: string]: Array<AfaMethodeSdDTO>; }>>;
    public readAfaMethodeSdsByKontenrahmenGroupedByAfaSchluessel(kontenrahmen: KontenrahmenDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readAfaMethodeSdsByKontenrahmenGroupedByAfaSchluessel.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<{ [key: string]: Array<AfaMethodeSdDTO>; }>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/afaSchluessel`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle AfaNutzungsdauerSd zurück
     * Gibt Schluesseldaten AfaNutzungsdauerSd zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaNutzungsdauerSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaNutzungsdauerSdDTO>>;
    public readAfaNutzungsdauerSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaNutzungsdauerSdDTO>>>;
    public readAfaNutzungsdauerSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaNutzungsdauerSdDTO>>>;
    public readAfaNutzungsdauerSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaNutzungsdauerSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaNutzungsdauerSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaTabellenBereichSd gefiltert nach Gültigkeitstag zurück
     * Gibt gefilterte AfaTabellenBereichSd zurück
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaTabellenBereichSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaTabellenBereichSdDTO>>;
    public readAfaTabellenBereichSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaTabellenBereichSdDTO>>>;
    public readAfaTabellenBereichSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaTabellenBereichSdDTO>>>;
    public readAfaTabellenBereichSdsByGueltigkeit(gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readAfaTabellenBereichSdsByGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaTabellenBereichSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaTabellenBereichSd/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaTabellenGebietSd gefiltert nach Gültigkeitstag zurück
     * Gibt gefilterte AfaTabellenGebietSd zurück
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaTabellenGebietSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaTabellenGebietSdDTO>>;
    public readAfaTabellenGebietSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaTabellenGebietSdDTO>>>;
    public readAfaTabellenGebietSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaTabellenGebietSdDTO>>>;
    public readAfaTabellenGebietSdsByGueltigkeit(gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readAfaTabellenGebietSdsByGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaTabellenGebietSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaTabellenGebietSd/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaTabellenNummerSd gefiltert nach Gültigkeitstag zurück
     * Gibt gefilterte AfaTabellenNummerSd zurück
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaTabellenNummerSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaTabellenNummerSdDTO>>;
    public readAfaTabellenNummerSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaTabellenNummerSdDTO>>>;
    public readAfaTabellenNummerSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaTabellenNummerSdDTO>>>;
    public readAfaTabellenNummerSdsByGueltigkeit(gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readAfaTabellenNummerSdsByGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaTabellenNummerSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaTabellenNummerSd/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die AfaTabellenSd gefiltert nach Gültigkeitstag zurück
     * Gibt gefilterte AfaTabellenSd zurück
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAfaTabellenSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AfaTabellenSdDTO>>;
    public readAfaTabellenSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AfaTabellenSdDTO>>>;
    public readAfaTabellenSdsByGueltigkeit(gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AfaTabellenSdDTO>>>;
    public readAfaTabellenSdsByGueltigkeit(gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readAfaTabellenSdsByGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AfaTabellenSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaTabellenSd/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle Finanzaemter zurück
     * Gibt Schluesseldaten Finanzaemter zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readFinanzaemter(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FinanzamtDTO>>;
    public readFinanzaemter(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FinanzamtDTO>>>;
    public readFinanzaemter(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FinanzamtDTO>>>;
    public readFinanzaemter(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FinanzamtDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/finanzaemter`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt gefilterte KontenAggregierungSd zurück
     * Gibt die KontenAggregierungSd ungefiltert zurück. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKontenAggregierungSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<KontenAggregierungSdDTO>>;
    public readKontenAggregierungSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<KontenAggregierungSdDTO>>>;
    public readKontenAggregierungSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<KontenAggregierungSdDTO>>>;
    public readKontenAggregierungSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<KontenAggregierungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontenaggregierungSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt gefilterte KontenAggregierungSd zurück
     * Gibt die KontenAggregierungSd gefiltert nach Kontenrahmen und Gueltigkeit zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param gueltigvon Gülstigkeitsdatum ab dem gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKontenAggregierungSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigvon: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<KontenAggregierungSdDTO>>;
    public readKontenAggregierungSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigvon: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<KontenAggregierungSdDTO>>>;
    public readKontenAggregierungSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigvon: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<KontenAggregierungSdDTO>>>;
    public readKontenAggregierungSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigvon: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readKontenAggregierungSdsByKontenrahmenGueltigkeit.');
        }
        if (gueltigvon === null || gueltigvon === undefined) {
            throw new Error('Required parameter gueltigvon was null or undefined when calling readKontenAggregierungSdsByKontenrahmenGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<KontenAggregierungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontenaggregierungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/gueltigVon/${encodeURIComponent(String(gueltigvon))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt gefilterte KontenAggregierungSd zurück
     * Gibt die KontenAggregierungSd gefiltert nach Kontenrahmen, Kontonummer und Aggregierungsart zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param kontonummer Die Kontonummer, auf die gefiltert werden soll.
     * @param aggregierungsart Aggregierungsart für das Filtern von KontenAggregierungSd.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart(kontenrahmen: KontenrahmenDTO, kontonummer: number, aggregierungsart: KontenAggregierungsartDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<KontenAggregierungSdDTO>>;
    public readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart(kontenrahmen: KontenrahmenDTO, kontonummer: number, aggregierungsart: KontenAggregierungsartDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<KontenAggregierungSdDTO>>>;
    public readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart(kontenrahmen: KontenrahmenDTO, kontonummer: number, aggregierungsart: KontenAggregierungsartDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<KontenAggregierungSdDTO>>>;
    public readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart(kontenrahmen: KontenrahmenDTO, kontonummer: number, aggregierungsart: KontenAggregierungsartDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart.');
        }
        if (kontonummer === null || kontonummer === undefined) {
            throw new Error('Required parameter kontonummer was null or undefined when calling readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart.');
        }
        if (aggregierungsart === null || aggregierungsart === undefined) {
            throw new Error('Required parameter aggregierungsart was null or undefined when calling readKontenAggregierungSdsByKontenrahmenKontonummerAggregierungsart.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<KontenAggregierungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontenaggregierungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/kontonummer/${encodeURIComponent(String(kontonummer))}/aggregierungsart/${encodeURIComponent(String(aggregierungsart))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle KontoSd zurück
     * Gibt Schluesseldaten KontoSd zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKontoSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<KontoSdDTO>>;
    public readKontoSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<KontoSdDTO>>>;
    public readKontoSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<KontoSdDTO>>>;
    public readKontoSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<KontoSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontoSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die KontoSd gefiltert nach Kontenrahmen zurück
     * Gibt gefilterte KontoSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKontoSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<KontoSdDTO>>;
    public readKontoSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<KontoSdDTO>>>;
    public readKontoSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<KontoSdDTO>>>;
    public readKontoSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readKontoSdsByKontenrahmen.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<KontoSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontoSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die KontoSd gefiltert nach Kontenrahmen &amp; Gültigkeitstag zurück
     * Gibt gefilterte KontoSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKontoSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<KontoSdDTO>>;
    public readKontoSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<KontoSdDTO>>>;
    public readKontoSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<KontoSdDTO>>>;
    public readKontoSdsByKontenrahmenGueltigkeit(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readKontoSdsByKontenrahmenGueltigkeit.');
        }
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readKontoSdsByKontenrahmenGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<KontoSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontoSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle Kostenstelle1Sd zurück
     * Gibt Schluesseldaten Kostenstelle1Sd zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readKostenstelle1Sds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Kostenstelle1SdDTO>>;
    public readKostenstelle1Sds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Kostenstelle1SdDTO>>>;
    public readKostenstelle1Sds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Kostenstelle1SdDTO>>>;
    public readKostenstelle1Sds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Kostenstelle1SdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/kostenstelle1Sd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle Laender zurück
     * Gibt Schluesseldaten Laender zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readLaender(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LandDTO>>;
    public readLaender(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LandDTO>>>;
    public readLaender(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LandDTO>>>;
    public readLaender(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LandDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/laender`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt ungefilterte MengeneinheitSd zurück
     * Gibt die MengeneinheitSd ungefiltert zurück. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readMengeneinheitSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MengeneinheitSdDTO>>;
    public readMengeneinheitSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MengeneinheitSdDTO>>>;
    public readMengeneinheitSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MengeneinheitSdDTO>>>;
    public readMengeneinheitSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<MengeneinheitSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/mengeneinheitSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle Schlüsseldaten zurück
     * Gibt alle Schluesseldaten zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSchluesseldaten(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SchluesseldatenDTO>;
    public readSchluesseldaten(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SchluesseldatenDTO>>;
    public readSchluesseldaten(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SchluesseldatenDTO>>;
    public readSchluesseldaten(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SchluesseldatenDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle SchlüsseldatenBuchhaltung zurück
     * Gibt alle SchluesseldatenBuchhaltung zurück
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSchluesseldatenBuchhaltung(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SchluesseldatenBuchhaltungDTO>;
    public readSchluesseldatenBuchhaltung(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SchluesseldatenBuchhaltungDTO>>;
    public readSchluesseldatenBuchhaltung(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SchluesseldatenBuchhaltungDTO>>;
    public readSchluesseldatenBuchhaltung(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SchluesseldatenBuchhaltungDTO>(`${this.configuration.basePath}/api/v1/schluesseldatenBuchhaltung`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach AfaSchluessel und AfaArt zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param afaSchluessel Der Schluessel auf die gefiltert werden soll.
     * @param afaArt Die AfaArt auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleAfaMethodeByAfaSchluesselAfaArt(afaSchluessel: number, afaArt: AfaArtDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AfaMethodeSdDTO>;
    public readSingleAfaMethodeByAfaSchluesselAfaArt(afaSchluessel: number, afaArt: AfaArtDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeByAfaSchluesselAfaArt(afaSchluessel: number, afaArt: AfaArtDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeByAfaSchluesselAfaArt(afaSchluessel: number, afaArt: AfaArtDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (afaSchluessel === null || afaSchluessel === undefined) {
            throw new Error('Required parameter afaSchluessel was null or undefined when calling readSingleAfaMethodeByAfaSchluesselAfaArt.');
        }
        if (afaArt === null || afaArt === undefined) {
            throw new Error('Required parameter afaArt was null or undefined when calling readSingleAfaMethodeByAfaSchluesselAfaArt.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AfaMethodeSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/afaSchluessel/${encodeURIComponent(String(afaSchluessel))}/afaArt/${encodeURIComponent(String(afaArt))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach AfaMethodeBetriebswirtschaftlich zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param afaMethodeBetriebswirtschaftlich Die AfaMethode auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleAfaMethodeSdByMethodeBetriebswirtschaftlich(afaMethodeBetriebswirtschaftlich: AfaMethodeBetriebswirtschaftlichDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AfaMethodeSdDTO>;
    public readSingleAfaMethodeSdByMethodeBetriebswirtschaftlich(afaMethodeBetriebswirtschaftlich: AfaMethodeBetriebswirtschaftlichDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeBetriebswirtschaftlich(afaMethodeBetriebswirtschaftlich: AfaMethodeBetriebswirtschaftlichDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeBetriebswirtschaftlich(afaMethodeBetriebswirtschaftlich: AfaMethodeBetriebswirtschaftlichDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (afaMethodeBetriebswirtschaftlich === null || afaMethodeBetriebswirtschaftlich === undefined) {
            throw new Error('Required parameter afaMethodeBetriebswirtschaftlich was null or undefined when calling readSingleAfaMethodeSdByMethodeBetriebswirtschaftlich.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AfaMethodeSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/afaMethodeBetriebswirtschaftlich/${encodeURIComponent(String(afaMethodeBetriebswirtschaftlich))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach AfaMethodeHandelsrechtlich zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param afaMethodeHandelsrechtlich Die AfaMethode auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleAfaMethodeSdByMethodeHandelsrechtlich(afaMethodeHandelsrechtlich: AfaMethodeHandelsrechtlichDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AfaMethodeSdDTO>;
    public readSingleAfaMethodeSdByMethodeHandelsrechtlich(afaMethodeHandelsrechtlich: AfaMethodeHandelsrechtlichDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeHandelsrechtlich(afaMethodeHandelsrechtlich: AfaMethodeHandelsrechtlichDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeHandelsrechtlich(afaMethodeHandelsrechtlich: AfaMethodeHandelsrechtlichDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (afaMethodeHandelsrechtlich === null || afaMethodeHandelsrechtlich === undefined) {
            throw new Error('Required parameter afaMethodeHandelsrechtlich was null or undefined when calling readSingleAfaMethodeSdByMethodeHandelsrechtlich.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AfaMethodeSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/afaMethodeHandelsrechtlich/${encodeURIComponent(String(afaMethodeHandelsrechtlich))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach AfaMethodeSonderAbschreibung zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param afaMethodeSonderAbschreibung Die AfaMethode auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleAfaMethodeSdByMethodeSonderAbschreibung(afaMethodeSonderAbschreibung: AfaMethodeSonderAbschreibungDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AfaMethodeSdDTO>;
    public readSingleAfaMethodeSdByMethodeSonderAbschreibung(afaMethodeSonderAbschreibung: AfaMethodeSonderAbschreibungDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeSonderAbschreibung(afaMethodeSonderAbschreibung: AfaMethodeSonderAbschreibungDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeSonderAbschreibung(afaMethodeSonderAbschreibung: AfaMethodeSonderAbschreibungDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (afaMethodeSonderAbschreibung === null || afaMethodeSonderAbschreibung === undefined) {
            throw new Error('Required parameter afaMethodeSonderAbschreibung was null or undefined when calling readSingleAfaMethodeSdByMethodeSonderAbschreibung.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AfaMethodeSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/afaMethodeSonderAbschreibung/${encodeURIComponent(String(afaMethodeSonderAbschreibung))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach AfaMethodeSteuerlich zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param afaMethodeSteuerlich Die AfaMethode auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleAfaMethodeSdByMethodeSteuerlich(afaMethodeSteuerlich: AfaMethodeSteuerlichDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AfaMethodeSdDTO>;
    public readSingleAfaMethodeSdByMethodeSteuerlich(afaMethodeSteuerlich: AfaMethodeSteuerlichDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeSteuerlich(afaMethodeSteuerlich: AfaMethodeSteuerlichDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeSteuerlich(afaMethodeSteuerlich: AfaMethodeSteuerlichDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (afaMethodeSteuerlich === null || afaMethodeSteuerlich === undefined) {
            throw new Error('Required parameter afaMethodeSteuerlich was null or undefined when calling readSingleAfaMethodeSdByMethodeSteuerlich.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AfaMethodeSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/afaMethodeSteuerlich/${encodeURIComponent(String(afaMethodeSteuerlich))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine AfaMethodeSd gefiltert nach AfaMethodeZuschreibung zurück
     * Gibt gefilterte AfaMethodeSd zurück
     * @param afaMethodeZuschreibung Die AfaMethode auf die gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleAfaMethodeSdByMethodeZuschreibung(afaMethodeZuschreibung: AfaMethodeZuschreibungDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AfaMethodeSdDTO>;
    public readSingleAfaMethodeSdByMethodeZuschreibung(afaMethodeZuschreibung: AfaMethodeZuschreibungDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeZuschreibung(afaMethodeZuschreibung: AfaMethodeZuschreibungDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AfaMethodeSdDTO>>;
    public readSingleAfaMethodeSdByMethodeZuschreibung(afaMethodeZuschreibung: AfaMethodeZuschreibungDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (afaMethodeZuschreibung === null || afaMethodeZuschreibung === undefined) {
            throw new Error('Required parameter afaMethodeZuschreibung was null or undefined when calling readSingleAfaMethodeSdByMethodeZuschreibung.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AfaMethodeSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/afaMethodeSd/afaMethodeZuschreibung/${encodeURIComponent(String(afaMethodeZuschreibung))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt ein KontoSd zurück, da gefiltert nach Kontenrahmen, Kontonummer &amp; Gültigkeitstag
     * Gibt ermitteltes KontoSd zurück. Für die Kombination Kontenrahmen + Kontonummer+ Gültigkeitstag ist immer max. ein KontoSD gültig.
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param kontonummer Die Kontonummer, auf die gefiltert werden soll.
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleKontoSd(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<KontoSdDTO>;
    public readSingleKontoSd(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<KontoSdDTO>>;
    public readSingleKontoSd(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<KontoSdDTO>>;
    public readSingleKontoSd(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readSingleKontoSd.');
        }
        if (kontonummer === null || kontonummer === undefined) {
            throw new Error('Required parameter kontonummer was null or undefined when calling readSingleKontoSd.');
        }
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readSingleKontoSd.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<KontoSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/kontoSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/kontonummer/${encodeURIComponent(String(kontonummer))}/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine MengeneinheitSd zurück
     * Gibt eine MengeneinheitSd gefiltert nach der Nummer zurück. 
     * @param mengeneinheitsNummer mengeneinheitsNummer nach der gefiltert werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MengeneinheitSdDTO>;
    public readSingleMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MengeneinheitSdDTO>>;
    public readSingleMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MengeneinheitSdDTO>>;
    public readSingleMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mengeneinheitsNummer === null || mengeneinheitsNummer === undefined) {
            throw new Error('Required parameter mengeneinheitsNummer was null or undefined when calling readSingleMengeneinheitSdByMengeneinheitsNummer.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<MengeneinheitSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/mengeneinheitSd/mengeneinheitsNummer/${encodeURIComponent(String(mengeneinheitsNummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt einen Umsatzsteuerschlüssel zurück
     * Gibt die passende UstSd für Kontenrahmen, Gueltigkeit, EinnahmeAusgabe und UstIdNummer zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param einnahmeAusgabe Gibt an ob der Umsatzsteuerschluessel für Einnahmen oder Ausgaben bestimmt ist. 
     * @param ustSchluesselNummer UstSchluesselNummer nach der gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleUstSd(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, einnahmeAusgabe: EinnahmeAusgabeDTO, ustSchluesselNummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UmsatzsteuerschluesselSdDTO>;
    public readSingleUstSd(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, einnahmeAusgabe: EinnahmeAusgabeDTO, ustSchluesselNummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UmsatzsteuerschluesselSdDTO>>;
    public readSingleUstSd(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, einnahmeAusgabe: EinnahmeAusgabeDTO, ustSchluesselNummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UmsatzsteuerschluesselSdDTO>>;
    public readSingleUstSd(kontenrahmen: KontenrahmenDTO, gueltigkeitstag: string, einnahmeAusgabe: EinnahmeAusgabeDTO, ustSchluesselNummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readSingleUstSd.');
        }
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readSingleUstSd.');
        }
        if (einnahmeAusgabe === null || einnahmeAusgabe === undefined) {
            throw new Error('Required parameter einnahmeAusgabe was null or undefined when calling readSingleUstSd.');
        }
        if (ustSchluesselNummer === null || ustSchluesselNummer === undefined) {
            throw new Error('Required parameter ustSchluesselNummer was null or undefined when calling readSingleUstSd.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<UmsatzsteuerschluesselSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/ustSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}/einnahmeAusgabe/${encodeURIComponent(String(einnahmeAusgabe))}/ustSchluesselNummer/${encodeURIComponent(String(ustSchluesselNummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine WertefeldMengeneinheitSd zurück
     * Gibt eine WertefeldMengeneinheitSd gefiltert nach der Nummer zurück. 
     * @param mengeneinheitsNummer mengeneinheitsNummer nach der gefiltert werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleWertefeldMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<WertefeldMengeneinheitSdDTO>;
    public readSingleWertefeldMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<WertefeldMengeneinheitSdDTO>>;
    public readSingleWertefeldMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<WertefeldMengeneinheitSdDTO>>;
    public readSingleWertefeldMengeneinheitSdByMengeneinheitsNummer(mengeneinheitsNummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mengeneinheitsNummer === null || mengeneinheitsNummer === undefined) {
            throw new Error('Required parameter mengeneinheitsNummer was null or undefined when calling readSingleWertefeldMengeneinheitSdByMengeneinheitsNummer.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WertefeldMengeneinheitSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/wertefeldMengeneinheitSd/mengeneinheitsNummer/${encodeURIComponent(String(mengeneinheitsNummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine Zuordnung zurück
     * Gibt die passende ZuordnungSd für Kontenrahmen, Kontonummer, Verwendungsbereich und Gueltigkeit zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param kontonummer Die Kontonummer, auf die gefiltert werden soll.
     * @param verwendungsbereich Verwendungsbereich nach dem gefiltert werden soll
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readSingleZuordnungSdByKontoNrVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ZuordnungSdDTO>;
    public readSingleZuordnungSdByKontoNrVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ZuordnungSdDTO>>;
    public readSingleZuordnungSdByKontoNrVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ZuordnungSdDTO>>;
    public readSingleZuordnungSdByKontoNrVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readSingleZuordnungSdByKontoNrVerwendungsbereichDatum.');
        }
        if (kontonummer === null || kontonummer === undefined) {
            throw new Error('Required parameter kontonummer was null or undefined when calling readSingleZuordnungSdByKontoNrVerwendungsbereichDatum.');
        }
        if (verwendungsbereich === null || verwendungsbereich === undefined) {
            throw new Error('Required parameter verwendungsbereich was null or undefined when calling readSingleZuordnungSdByKontoNrVerwendungsbereichDatum.');
        }
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readSingleZuordnungSdByKontoNrVerwendungsbereichDatum.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ZuordnungSdDTO>(`${this.configuration.basePath}/api/v1/schluesseldaten/zuordnungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/kontonummer/${encodeURIComponent(String(kontonummer))}/verwendungsbereich/${encodeURIComponent(String(verwendungsbereich))}/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle UstSd zurück
     * Gibt die UstSd ohne Filterung  zurück. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readUstSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<UmsatzsteuerschluesselSdDTO>>;
    public readUstSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<UmsatzsteuerschluesselSdDTO>>>;
    public readUstSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<UmsatzsteuerschluesselSdDTO>>>;
    public readUstSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<UmsatzsteuerschluesselSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/ustSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt gefilterte UstSd zurück
     * Gibt die UstSd gefiltert nach Umsatzbesteuerungsform, Kontenrahmen und Gueltigkeit  zurück. 
     * @param umsatzsteuerBesteuerungsform UmsatzsteuerBesteuerungsform für das Filtern von Umsatzsteuerschluessel.
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param gueltigvon Gülstigkeitsdatum ab dem gefiltert wird.
     * @param gueltigbis Gülstigkeitsdatum bis zu dem gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readUstSdsByKontenrahmenGueltigkeit(umsatzsteuerBesteuerungsform: UmsatzsteuerBesteuerungsformDTO, kontenrahmen: KontenrahmenDTO, gueltigvon: string, gueltigbis: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<UmsatzsteuerschluesselSdDTO>>;
    public readUstSdsByKontenrahmenGueltigkeit(umsatzsteuerBesteuerungsform: UmsatzsteuerBesteuerungsformDTO, kontenrahmen: KontenrahmenDTO, gueltigvon: string, gueltigbis: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<UmsatzsteuerschluesselSdDTO>>>;
    public readUstSdsByKontenrahmenGueltigkeit(umsatzsteuerBesteuerungsform: UmsatzsteuerBesteuerungsformDTO, kontenrahmen: KontenrahmenDTO, gueltigvon: string, gueltigbis: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<UmsatzsteuerschluesselSdDTO>>>;
    public readUstSdsByKontenrahmenGueltigkeit(umsatzsteuerBesteuerungsform: UmsatzsteuerBesteuerungsformDTO, kontenrahmen: KontenrahmenDTO, gueltigvon: string, gueltigbis: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (umsatzsteuerBesteuerungsform === null || umsatzsteuerBesteuerungsform === undefined) {
            throw new Error('Required parameter umsatzsteuerBesteuerungsform was null or undefined when calling readUstSdsByKontenrahmenGueltigkeit.');
        }
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readUstSdsByKontenrahmenGueltigkeit.');
        }
        if (gueltigvon === null || gueltigvon === undefined) {
            throw new Error('Required parameter gueltigvon was null or undefined when calling readUstSdsByKontenrahmenGueltigkeit.');
        }
        if (gueltigbis === null || gueltigbis === undefined) {
            throw new Error('Required parameter gueltigbis was null or undefined when calling readUstSdsByKontenrahmenGueltigkeit.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<UmsatzsteuerschluesselSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/ustSd/umsatzsteuerBesteuerungsform/${encodeURIComponent(String(umsatzsteuerBesteuerungsform))}/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/gueltigVon/${encodeURIComponent(String(gueltigvon))}/gueltigBis/${encodeURIComponent(String(gueltigbis))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt ungefilterte WertefeldMengeneinheitSd zurück
     * Gibt die WertefeldMengeneinheitSd ungefiltert zurück. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readWertefeldMengeneinheitSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<WertefeldMengeneinheitSdDTO>>;
    public readWertefeldMengeneinheitSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<WertefeldMengeneinheitSdDTO>>>;
    public readWertefeldMengeneinheitSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<WertefeldMengeneinheitSdDTO>>>;
    public readWertefeldMengeneinheitSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WertefeldMengeneinheitSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/wertefeldMengeneinheitSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt ungefilterte WertefeldSchluesselzahlSd zurück
     * Gibt die WertefeldSchluesselzahlSd ungefiltert zurück. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readWertefeldSchluesselzahlSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<WertefeldSchluesselzahlSdDTO>>;
    public readWertefeldSchluesselzahlSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<WertefeldSchluesselzahlSdDTO>>>;
    public readWertefeldSchluesselzahlSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<WertefeldSchluesselzahlSdDTO>>>;
    public readWertefeldSchluesselzahlSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WertefeldSchluesselzahlSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/wertefeldSchluesselzahlSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt ungefilterte WertefeldZuordnungSd zurück
     * Gibt die WertefeldZuordnungSd ungefiltert zurück. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readWertefeldZuordnungSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<WertefeldZuordnungSdDTO>>;
    public readWertefeldZuordnungSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<WertefeldZuordnungSdDTO>>>;
    public readWertefeldZuordnungSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<WertefeldZuordnungSdDTO>>>;
    public readWertefeldZuordnungSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WertefeldZuordnungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/wertefeldZuordnungSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt gefilterte WertefeldZuordnungSd zurück
     * Gibt die WertefeldZuordnungSd gefiltert nach Kontenrahmen, Kontonummer und GuleitgVon zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param kontonummer Die Kontonummer, auf die gefiltert werden soll.
     * @param gueltigvon Gülstigkeitsdatum ab dem gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readWertefeldZuordnungSdsByKontonummerAndGueltigVon(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigvon: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<WertefeldZuordnungSdDTO>>;
    public readWertefeldZuordnungSdsByKontonummerAndGueltigVon(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigvon: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<WertefeldZuordnungSdDTO>>>;
    public readWertefeldZuordnungSdsByKontonummerAndGueltigVon(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigvon: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<WertefeldZuordnungSdDTO>>>;
    public readWertefeldZuordnungSdsByKontonummerAndGueltigVon(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigvon: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readWertefeldZuordnungSdsByKontonummerAndGueltigVon.');
        }
        if (kontonummer === null || kontonummer === undefined) {
            throw new Error('Required parameter kontonummer was null or undefined when calling readWertefeldZuordnungSdsByKontonummerAndGueltigVon.');
        }
        if (gueltigvon === null || gueltigvon === undefined) {
            throw new Error('Required parameter gueltigvon was null or undefined when calling readWertefeldZuordnungSdsByKontonummerAndGueltigVon.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WertefeldZuordnungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/wertefeldZuordnungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/kontonummer/${encodeURIComponent(String(kontonummer))}/gueltigvon/${encodeURIComponent(String(gueltigvon))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die ZuordnungSd ungefiltert nach Kontenrahmen zurück
     * Gibt ungefilterte ZuordnungSd zurück 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readZuordnungSds(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ZuordnungSdDTO>>;
    public readZuordnungSds(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ZuordnungSdDTO>>>;
    public readZuordnungSds(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ZuordnungSdDTO>>>;
    public readZuordnungSds(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ZuordnungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/zuordnungSd`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die ZuordnungSd gefiltert nach Kontenrahmen zurück
     * Gibt gefilterte ZuordnungSd zurück
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readZuordnungSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ZuordnungSdDTO>>;
    public readZuordnungSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ZuordnungSdDTO>>>;
    public readZuordnungSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ZuordnungSdDTO>>>;
    public readZuordnungSdsByKontenrahmen(kontenrahmen: KontenrahmenDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readZuordnungSdsByKontenrahmen.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ZuordnungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/zuordnungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt Zuordnungen zurück
     * Gibt die passende ZuordnungSd für Kontenrahmen, Kontonummer und Gueltigkeit zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param kontonummer Die Kontonummer, auf die gefiltert werden soll.
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readZuordnungSdsByKontoNrDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ZuordnungSdDTO>>;
    public readZuordnungSdsByKontoNrDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ZuordnungSdDTO>>>;
    public readZuordnungSdsByKontoNrDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ZuordnungSdDTO>>>;
    public readZuordnungSdsByKontoNrDatum(kontenrahmen: KontenrahmenDTO, kontonummer: number, gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readZuordnungSdsByKontoNrDatum.');
        }
        if (kontonummer === null || kontonummer === undefined) {
            throw new Error('Required parameter kontonummer was null or undefined when calling readZuordnungSdsByKontoNrDatum.');
        }
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readZuordnungSdsByKontoNrDatum.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ZuordnungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/zuordnungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/kontonummer/${encodeURIComponent(String(kontonummer))}/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt Zuordnungen zurück
     * Gibt die passende ZuordnungSd für Kontenrahmen, Verwendungsbereich und Gueltigkeit zurück. 
     * @param kontenrahmen Der Kontenrahmen auf den gefiltert werden soll.
     * @param verwendungsbereich Verwendungsbereich nach dem gefiltert werden soll
     * @param gueltigkeitstag Der Gültigkeitstag, auf den gefiltert wird.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readZuordnungSdsByVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ZuordnungSdDTO>>;
    public readZuordnungSdsByVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ZuordnungSdDTO>>>;
    public readZuordnungSdsByVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ZuordnungSdDTO>>>;
    public readZuordnungSdsByVerwendungsbereichDatum(kontenrahmen: KontenrahmenDTO, verwendungsbereich: VerwendungsbereichDTO, gueltigkeitstag: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (kontenrahmen === null || kontenrahmen === undefined) {
            throw new Error('Required parameter kontenrahmen was null or undefined when calling readZuordnungSdsByVerwendungsbereichDatum.');
        }
        if (verwendungsbereich === null || verwendungsbereich === undefined) {
            throw new Error('Required parameter verwendungsbereich was null or undefined when calling readZuordnungSdsByVerwendungsbereichDatum.');
        }
        if (gueltigkeitstag === null || gueltigkeitstag === undefined) {
            throw new Error('Required parameter gueltigkeitstag was null or undefined when calling readZuordnungSdsByVerwendungsbereichDatum.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ZuordnungSdDTO>>(`${this.configuration.basePath}/api/v1/schluesseldaten/zuordnungSd/kontenrahmen/${encodeURIComponent(String(kontenrahmen))}/verwendungsbereich/${encodeURIComponent(String(verwendungsbereich))}/gueltigkeitstag/${encodeURIComponent(String(gueltigkeitstag))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
