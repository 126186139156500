import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class DeleteKundeDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.deleteKundeDialog.isOpen,
    betriebId: (state: AppState) => state.deleteKundeDialog.inhaberId,
    kundeId: (state: AppState) => state.deleteKundeDialog.kundeId,
  };

  public static isDeleteKundeDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static deleteData = createSelector(
    this.select.betriebId,
    this.select.kundeId,
    (betriebId, kundeId) => {
      return {
        betriebId,
        kundeId,
      };
    }
  );
}
