<div class="form">

  <div
    class="header">
    <h1
      class="dialog-h1"
      i18n
    >
      {{ formInput.title }}
    </h1>
    <ng-content select=".header-content"></ng-content>
  </div>

  <div class="form-fields" i18n>
    <ng-content select=".body-content"></ng-content>
  </div>

  <mat-divider></mat-divider>

  <div class="footer">
    <mat-divider class="divider"></mat-divider>
    <ng-content select=".footer-content"></ng-content>
    <jf-button
      [variant]="'ghost'"
      [text]="formInput.cancelButtonLabel"
      (click)="doClickCancel()"
    ></jf-button>
  </div>

</div>
