import {mengeneinheitEntitiesAdapter} from '../adapters/mengeneinheit-entities.adapter';
import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';


export class MengeneinheitEntitiesSelectors {

  private static adapterSelectors = mengeneinheitEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    mengeneinheitEntities: (state: AppState) => state.mengeneinheitEntities,
  };

  public static all = createSelector(
    this.select.mengeneinheitEntities,
    this.adapterSelectors.selectAll,
  );

  public static mengeneinheitByBetriebAndNummer = (betriebId: string, mengeneinheitNummer: number) => createSelector(
    this.all,
    mengeneinheiten => mengeneinheiten.find(mengeneinheit => {
      return mengeneinheit.betriebId === betriebId && mengeneinheit.nummer === mengeneinheitNummer;
    }),
  );

  public static mengeneinheitenByBetrieb = (betriebId: string) => createSelector(
    this.all,
    mengeneinheiten => mengeneinheiten.filter(mengeneinheit => {
      return mengeneinheit.betriebId === betriebId;
    }),
  );

}
