import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {MengeneinheitExtended} from '../../interfaces/mengeneinheit-extended.interface';


export class MengeneinheitEntitiesActions {

  private static readonly prefix = '[Mengeneinheit Entities] ';

  public static readMengeneinheit = createAction(
    this.prefix + 'Read list of mengeneinheit.',
    props<{
      betriebId: string,
    }>(),
  );

  public static readMengeneinheitSuccess = createAction(
    this.prefix + 'Read list of mengeneinheit successfully.',
    props<{
      betriebId: string,
      mengeneinheitDtos: MengeneinheitExtended[],
    }>(),
  );

  public static readMengeneinheitFailed = createAction(
    this.prefix + 'Read list of mengeneinheit failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

}
