import {
  BerechnungsregelDTO,
  BerechnungsregelMengeMalEinzelpreisDTO,
  ProduktDTO,
  ProduktPageDTO
} from '../../openapi/fakturierung-openapi';


export const produktMock1: ProduktDTO = {
  id: '1',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  bezeichnung: 'Das ist ein Produkt A, das sehr gut ist.',
  nummer: 1001,
  beschreibung: 'Dies ist Produkt A',
  berechnungsregel: {
    typ: 'mengeMalEinzelpreis',
    betrag: 300,
    bruttoberechnung: false,
    menge: 3,
    mengeneinheitNummer: 28,
    umsatzsteuerschluesselNummer: 2,
  } as BerechnungsregelMengeMalEinzelpreisDTO,
};

export const produktMock2: ProduktDTO = {
  id: '2',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  bezeichnung: 'Das Produkt B ist auch nicht schlecht.',
  nummer: 1002,
  beschreibung: 'Dies ist Produkt B',
  berechnungsregel: {
    typ: 'mengeMalEinzelpreis',
    betrag: 200,
    bruttoberechnung: true,
    menge: 1,
    mengeneinheitNummer: 27,
    umsatzsteuerschluesselNummer: 1,
  } as BerechnungsregelMengeMalEinzelpreisDTO,
};

export const produktMock3: ProduktDTO = {
  id: '999',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  bezeichnung: 'Osterei',
  nummer: 1,
  beschreibung: 'Bunte Ostereier in Gang 4',
  berechnungsregel: {
    typ: 'mengeMalEinzelpreis',
    betrag: 65,
    bruttoberechnung: true,
    menge: 6,
    mengeneinheitNummer: 23,
    umsatzsteuerschluesselNummer: 1,
  } as BerechnungsregelMengeMalEinzelpreisDTO,
};

export const produkteMock: ProduktDTO[] = [
  produktMock1,
  produktMock2,
  produktMock3,
];

export const produktEntitiesMock = {
  '1': produktMock1,
  '2': produktMock2,
  '3': produktMock3,
};

// Extraktion aller Produkt-IDs für einfache Nachverfolgung
export const produktIdsMock = Object.keys(produktEntitiesMock);

export const produktPageMock: ProduktPageDTO = {
  content: produkteMock,
  totalElements: produkteMock.length,
  elements: produkteMock.length,
  pageable: {
    number: 0,
    size: 10,
  },
};
