import {Component, inject} from '@angular/core';
import {BelegDTO} from '../../../../../openapi/fakturierung-openapi';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';
import {DataHolder} from '../../../../components/table/table-wrapper/table/data-holder';

@Component({
  standalone: true,
  selector: 'app-bruttobetrag.cell',
  templateUrl: './bruttobetrag.cell.component.html',
})
export class BruttobetragCellComponent {

  protected data?: BelegDTO = (inject(ELEMENT_DATA) as DataHolder<BelegDTO>).data;

}
