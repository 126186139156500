import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {MengeneinheitService} from '../../openapi/fakturierung-openapi';
import {MengeneinheitEntitiesActions} from '../actions/mengeneinheit-entities.actions';
import {of} from 'rxjs';
import {mappedHttpErrorResponse} from '../../operator-services/mapped-http-error-response';
import {MengeneinheitExtended} from '../../interfaces/mengeneinheit-extended.interface';


@Injectable()
export class MengeneinheitEntitiesEffects {

  private actions$ = inject(Actions);
  private logger = inject(NGXLogger);
  private snackbar = inject(MatSnackBar);
  private mengeneinheitService = inject(MengeneinheitService);

  readonly setCurrentInhaberIdFromURL$ = createEffect(
    () => this.actions$.pipe(
      ofType(InhaberEntitiesActions.setCurrentInhaberIdFromURL),
      map(action => action.currentInhaberId),
      filter((currentInhaberId): currentInhaberId is string => !!currentInhaberId),
      map(currentInhaberId => MengeneinheitEntitiesActions.readMengeneinheit({betriebId: currentInhaberId})),
    ),
  );

  readonly readMengeneinheit$ = createEffect(
    () => this.actions$.pipe(
      ofType(MengeneinheitEntitiesActions.readMengeneinheit),
      map(action => action.betriebId),
      switchMap(betriebId => this.mengeneinheitService.readMengeneinheiten(betriebId).pipe(
        map(mengeneinheitDtos => {
          this.logger.debug('read mengeneinheit succeeded.');
          return MengeneinheitEntitiesActions.readMengeneinheitSuccess({
            betriebId,
            mengeneinheitDtos: mengeneinheitDtos as MengeneinheitExtended[],
          });
        }),
        catchError(error => of(error).pipe(
          mappedHttpErrorResponse(error),
          switchMap(error => {
            this.logger.error('read mengeneinheit failed.', 'error:', error);
            return [MengeneinheitEntitiesActions.readMengeneinheitFailed({error})];
          }),
        ))
      )),
    ),
  );

}
