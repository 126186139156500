import {positionenMock} from './positionen.mock';
import {BelegDTO} from '../../openapi/fakturierung-openapi';

export const fakturierungsbelegMock1: BelegDTO = {
  id: '1',
  zahlungszielDatum: '01.01.2021',
  belegnummer: 'R-1-2024',
  kundenbezeichnung: 'Max Mustermann',
  kundennummer: 10000,
  datum: '12.10.2024',
  nachlaufzeilen: [
    'Wir danken für Ihren Auftrag und freuen uns auf eine gute Zusammenarbeit.',
    'Bitte überweisen Sie den Betrag innerhalb von 14 Tagen auf unser Konto.',
  ],
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  positionen: positionenMock,
  kundeId: '1',
  vorlaufzeile: 'Danke, danke, danke. Wir erlauben uns, die nachfolgenden Dinge zu berechnen:',
  statusEntwurf: true,
  statusFestgeschrieben: false,
  versandstatus: false,
  statusStorniert: false,
  bruttoBetragssumme: 1000,
};

export const fakturierungsbelegMock2: BelegDTO = {
  id: '2',
  zahlungszielDatum: '01.01.2021',
  belegnummer: 'R-2-2024',
  kundenbezeichnung: 'Tim Tom',
  kundennummer: 10000,
  datum: '01.01.2021',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  kundeId: '29',
  vorlaufzeile: 'Dies ist ein Begrüßungstext',
  statusEntwurf: false,
  statusFestgeschrieben: true,
  versandstatus: false,
}

export const fakturierungsbelegMock3: BelegDTO = {
  id: '3',
  zahlungszielDatum: '01.01.2021',
  belegnummer: 'R-3-2024',
  kundenbezeichnung: 'Bob Blob',
  kundennummer: 10000,
  datum: '22.05.2023',
  positionen: positionenMock,
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  kundeId: '10',
  vorlaufzeile: 'Sieh zu, dass du bezahlst Junge!',
  statusEntwurf: true,
  statusFestgeschrieben: false,
  versandstatus: true,
}

export const fakturierungsbelegeMock: BelegDTO[] = [
  fakturierungsbelegMock1,
  fakturierungsbelegMock2,
  fakturierungsbelegMock3,
];

export const fakturierungsbelegEntitiesMock = {
  '1': fakturierungsbelegMock1,
  '2': fakturierungsbelegMock2,
  '3': fakturierungsbelegMock3,
};

export const fakturierungsbelegIdsMock = Object.keys(fakturierungsbelegEntitiesMock);

