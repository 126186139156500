<div
  class="formfield-wrapper"
  *ngIf="formControls && anredeOptions && landOptions"
>
  <mat-divider class="topline"></mat-divider>

  <jf-form-field-select
    class="anrede"
    [label]="'Anrede'"
    [formControl]="formControls.controls.anrede"
    [options]="anredeOptions"
  >
  </jf-form-field-select>
  <jf-form-field-text
    [formControl]="formControls.controls.titel"
    [label]="'Titel'"
    class="titel"
  >
  </jf-form-field-text>
  <jf-form-field-number
    class="kundennummer"
    [message]="kundennummerValidatorMessage"
    [label]="'Kundennummer'"
    [formControl]="formControls.controls.kundennummer"
  >
  </jf-form-field-number>
  <jf-form-field-text
    class="vorname"
    [label]="'Vorname'"
    [formControl]="formControls.controls.vorname"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="nachname"
    [label]="'Nachname'"
    [formControl]="formControls.controls.nachname"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="strasse"
    [label]="'Straße + Hausnummer'"
    [formControl]="formControls.controls.strasseHausnummer"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="plz"
    [maxlength]="5"
    [label]="'PLZ'"
    [formControl]="formControls.controls.plz"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="ort"
    [label]="'Ort'"
    [formControl]="formControls.controls.ort"
  >
  </jf-form-field-text>
  <jf-form-field-select
    class="land"
    [label]="'Land'"
    [formControl]="formControls.controls.landPrivat"
    [options]="landOptions"
  >
  </jf-form-field-select>
  <jf-form-field-text
    class="email"
    [label]="'E-Mail-Adresse'"
    [formControl]="formControls.controls.email"
  >
  </jf-form-field-text>
</div>
