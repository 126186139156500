import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TableRowButton} from './table-row-button.interface';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-table-row-buttons',
  templateUrl: './table-row-buttons.component.html',
  styleUrls: ['./table-row-buttons.component.scss']
})
export class TableRowButtonsComponent {


  @Input() primaryButton?: TableRowButton;

  @Input() secondaryButton?: TableRowButton;

  @Input() tertiaryButton?: TableRowButton;

  @Input() moreOptionPrimaryButtons: TableRowButton[] = [];

  @Input() moreOptionSecondaryButtons: TableRowButton[] = [];

  @Input() moreOptionTertiaryButtons: TableRowButton[] = [];

  @Input() showMoreOptionButtons = false;

  @Output() buttonIdClick = new EventEmitter<string>();

  protected buttonClick($event: MouseEvent, id: string) {
    $event.stopPropagation();

    this.buttonIdClick.emit(id);
  }

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  openMenu(event: MouseEvent) {
    //INFO: Dem Mat Menu Trigger muss Event Propagation hinzu gefügt werden, da die Table Row eigene Click Events hat
    event.stopPropagation();
  }
}
