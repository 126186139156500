import {FakturierungsbelegFormState} from '../states/fakturierungsbeleg-form.state';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {FakturierungsbelegFormDto} from '../../interfaces/fakturierungsbeleg-form-dto.interface';
import {PositionAnzahlMode} from '../../interfaces/position-anzahl-modes.interface';
import {PositionDTO} from '../../openapi/fakturierung-openapi';
import {PositionBetragssummeMode} from '../../interfaces/position-betragssumme-mode.interface';
import {environment} from '../../../environments/environment';
import {KundeEntitiesActions} from '../actions/kunde-entities.actions';


export const initialFakturierungsbelegFormState: FakturierungsbelegFormState = {
  vorlaufzeileMode: 'display',
  leistungsempfaengerMode: 'display',
  positionAnzahlModes: [],
  positionBetragssummeModes: [],
  addProduktMode: 'display',
  fakturierungsbelegFormDto: {
    bruttoBetragssumme: 0,
  } as FakturierungsbelegFormDto,
  rechnungsdatumMode: 'display',
  leistungsLieferdatumControlMode: 'display',
  leistungsLieferdatumDateMode: 'single',
  nachlaufzeilen: [],
  logoUrl: null,
  isFormValid: false
};

export const fakturierungsbelegFormReducer = createReducer(
  initialFakturierungsbelegFormState,

  on(FakturierungsbelegFormActions.reset, () => initialFakturierungsbelegFormState),

  on(FakturierungsbelegFormActions.updateFormValidity, (state) => {
    const isThereAtLeastOnePosition = (state.fakturierungsbelegFormDto?.positionen?.length ?? 0) > 0;

    const leistungsempfaengerIsSet = !!state.fakturierungsbelegFormDto?.kundeId;
    let leistungsempfaengerMustBeSet = false;

    if ((state.fakturierungsbelegFormDto?.bruttoBetragssumme ?? 0) > environment.leistungsempfangerThresholdAmount) {
      leistungsempfaengerMustBeSet = true;
    }

    const isFormValid =
      isThereAtLeastOnePosition &&
      (!leistungsempfaengerMustBeSet || leistungsempfaengerIsSet);  // INFO: If leistungsempfängerMustBeSet is true, leistungsempfängerIsSet must be true; if leistungsempfängerMustBeSet is false, leistungsempfängerIsSet is irrelevant.

    return {
      ...state,
      isFormValid
    };
  }),

  on(FakturierungsbelegFormActions.updateFakturierungsbelegFormDto, (state, {fakturierungsbelegDto}) => {
    const positionAnzahlModes: PositionAnzahlMode[] = [];
    const positionBetragssummeModes: PositionBetragssummeMode[] = [];
    for (const position of fakturierungsbelegDto.positionen || []) {
      positionAnzahlModes.push({
        nummer: position.nummer,
        mode: 'display',
      });

      positionBetragssummeModes.push({
        nummer: position.nummer,
        mode: 'display',
      });
    }

    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...fakturierungsbelegDto,
    };

    return {
      ...initialFakturierungsbelegFormState,
      fakturierungsbelegFormDto,
      positionAnzahlModes,
      positionBetragssummeModes,
    };
  }),

  on(FakturierungsbelegFormActions.getLogoSuccess, (state, {logoUrl}) => ({
    ...state,
    logoUrl,
  })),

  on(FakturierungsbelegFormActions.updateGesamtBruttoBetrag, (state, {gesamtBruttoBetrag}) => ({
    ...state,
    fakturierungsbelegFormDto: {
      ...state.fakturierungsbelegFormDto!,
      bruttoBetragssumme: gesamtBruttoBetrag,
    },
  })),

  on(FakturierungsbelegFormActions.saveLogoSuccess, (state, {logoUrl}) => ({
    ...state,
    logoUrl,
  })),

  on(FakturierungsbelegFormActions.deleteLogoSuccess, (state) => ({
    ...state,
    logoUrl: null,
  })),

  on(FakturierungsbelegFormActions.releaseLogoFromMemory, (state) => ({
    ...state,
    logoUrl: null,
  })),

  on(FakturierungsbelegFormActions.setLeistungsempfaenger, (state, {kundeId}) => {
    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
      kundeId,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.changeLeistungsempfaengerMode, (state, {mode}) => {
    return {
      ...state,
      leistungsempfaengerMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeVorlaufzeileMode, (state, {mode}) => {
    return {
      ...state,
      vorlaufzeileMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.setVorlaufzeileText, (state, {text}) => {
    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
      vorlaufzeile: text,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.removePositionByNumber, (state, {number}) => {
    const positionen = [...state.fakturierungsbelegFormDto!.positionen || []]
      .filter(position => position.nummer !== number)
      .map(position => {
        if (position.nummer > number) {
          return {
            ...position,
            nummer: position.nummer - 1
          };
        }
        return position;
      });

    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
      positionen,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.changePositionAnzahlMode, (state, {positionAnzahlMode}) => {
    const positionAnzahlModes = [
      ...state.positionAnzahlModes || [],
    ];

    const positionAnzahlModeIndex = positionAnzahlModes.findIndex(item => {
      return item.nummer === positionAnzahlMode.nummer;
    });

    if (positionAnzahlModeIndex != undefined && positionAnzahlModeIndex !== -1) {
      positionAnzahlModes.splice(positionAnzahlModeIndex, 1);
    }

    positionAnzahlModes.push({
      nummer: positionAnzahlMode.nummer,
      mode: positionAnzahlMode.mode,
    });

    return {
      ...state,
      positionAnzahlModes,
    };
  }),

  on(FakturierungsbelegFormActions.setPositionAnzahl, (state, {positionAnzahl}) => {
    const positionen = [
      ...state.fakturierungsbelegFormDto!.positionen || [],
    ];

    const positionIndex = positionen.findIndex(item => item.nummer === positionAnzahl.nummer);
    if (positionIndex != undefined && positionIndex !== -1) {
      positionen[positionIndex] = {
        ...positionen[positionIndex],
        anzahl: positionAnzahl.anzahl,
      };
    }

    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
      positionen,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.changePositionBetragssummeMode, (state, {positionBetragssummeMode}) => {
    const positionBetragssummeModes = [
      ...state.positionBetragssummeModes || [],
    ];

    const positionBetragssummeModeIndex = positionBetragssummeModes.findIndex(item => {
      return item.nummer === positionBetragssummeMode.nummer;
    });
    if (positionBetragssummeModeIndex != undefined && positionBetragssummeModeIndex !== -1) {
      positionBetragssummeModes.splice(positionBetragssummeModeIndex, 1);
    }

    positionBetragssummeModes.push({
      nummer: positionBetragssummeMode.nummer,
      mode: positionBetragssummeMode.mode,
    });

    return {
      ...state,
      positionBetragssummeModes,
    };
  }),

  on(FakturierungsbelegFormActions.setPositionBetragssummme, (state, {positionBetragssumme}) => {
    const positionen = [
      ...state.fakturierungsbelegFormDto!.positionen || [],
    ];

    const positionIndex = positionen.findIndex(item => {
      return item.nummer === positionBetragssumme.nummer;
    });
    if (positionIndex != undefined && positionIndex !== -1) {
      positionen[positionIndex] = {
        ...positionen[positionIndex],
        betragssumme: positionBetragssumme.betragssumme,
      };
    }

    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
      positionen,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.changeAddProduktMode, (state, {mode}) => {
    return {
      ...state,
      addProduktMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.addPosition, (state, {produktDto}) => {
    let fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
    };

    const previousPositionen: PositionDTO[] = fakturierungsbelegFormDto.positionen || [];

    const newPositionDto: PositionDTO = {
      nummer: previousPositionen ? previousPositionen.length + 1 : 1,
      produktId: produktDto.id,
      anzahl: 1,

      // FIXME: Mit Team ARC klären
      // betragssumme: produktDto.betragssumme,
      betragssumme: 0,
    };

    const updatedPositionen = [
      ...previousPositionen || [],
      newPositionDto,
    ];

    fakturierungsbelegFormDto = {
      ...fakturierungsbelegFormDto,
      positionen: updatedPositionen,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.changeRechnungsdatumMode, (state, {mode}) => {
    return {
      ...state,
      rechnungsdatumMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeLeistungsLieferDatumControlMode, (state, {mode}) => {
    return {
      ...state,
      leistungsLieferdatumControlMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeLeistungsLieferDatumDateMode, (state, {mode}) => {
    return {
      ...state,
      leistungsLieferdatumDateMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.setRechnungsdatum, (state, {rechnungsdatum}) => {
    const fakturierungsbelegFormDto: FakturierungsbelegFormDto = {
      ...state.fakturierungsbelegFormDto!,
      datum: rechnungsdatum,
    };

    return {
      ...state,
      fakturierungsbelegFormDto,
    };
  }),

  on(FakturierungsbelegFormActions.addNachlaufzeile, (state, {nachlaufzeile}) => {
    const nachlaufzeilen = [...state.nachlaufzeilen || []].concat(nachlaufzeile);

    return {
      ...state,
      nachlaufzeilen,
    };
  }),

  on(FakturierungsbelegFormActions.setNachlaufzeilen, (state, {nachlaufzeilen}) => ({
    ...state,
    nachlaufzeilen,
  })),

  on(FakturierungsbelegFormActions.removeNachlaufzeile, (state, {nachlaufzeileId}) => {
    const nachlaufzeilen = [...state.nachlaufzeilen || []].filter(
      zeile => zeile.id !== nachlaufzeileId,
    );

    return {
      ...state,
      nachlaufzeilen,
    };
  }),

  on(FakturierungsbelegFormActions.toggleNachlaufzeilenMode, (state, {nachlaufzeileId}) => {
    const nachlaufzeilen = [...state.nachlaufzeilen || []].map(
      text => text.id === nachlaufzeileId ? {...text, editing: !text.editing} : {...text, editing: false},
    );

    return {
      ...state,
      nachlaufzeilen,
    };
  }),

  // on(KundeEntitiesActions.createKundeSuccess, (state, {kundeDto}) => {
  //   const currentFakturierungsbelegFormDto = state.fakturierungsbelegFormDto;
  //   let fakturierungsbelegFormDto: FakturierungsbelegFormDto | undefined;
  //   if (currentFakturierungsbelegFormDto) {
  //     fakturierungsbelegFormDto = {
  //       ...currentFakturierungsbelegFormDto,
  //       kundeId: kundeDto.id,
  //     };
  //   }
  //
  //   return {
  //     ...state,
  //     fakturierungsbelegFormDto,
  //   };
  // }),
);
