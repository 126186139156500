import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {KundeService} from '../../openapi/fakturierung-openapi';


@Injectable()
export class KundeDialogEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private kundeService: KundeService,
    private snackbar: MatSnackBar,
    private store: Store,
  ) {
  }


}
