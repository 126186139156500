<div class="dialog-wrapper">

  <h1
    class="dialog-h1"
    i18n
  >
    Kontakt löschen
  </h1>

  <div
    class="beleg-data"
    i18n
  >
    <div *ngIf="kundeKontaktDaten?.nachname || kundeKontaktDaten?.vorname">
      <strong>{{ kundeKontaktDaten?.vorname }} {{ kundeKontaktDaten?.nachname }}</strong>
    </div>

    <div
      *ngIf="kundeDto?.kundendaten?.typ === 'Geschaeftskundendaten' && kundeKontaktDaten?.bezeichnung">
      {{ kundeKontaktDaten?.bezeichnung }}
    </div>

    <div *ngIf="kundeKontaktDaten?.strasseHausnummer || kundeKontaktDaten?.postleitzahl || kundeKontaktDaten?.ort">
      {{ kundeKontaktDaten?.strasseHausnummer }}, {{ kundeKontaktDaten?.postleitzahl }} {{ kundeKontaktDaten?.ort }}
    </div>

    <div *ngIf="kundeDto?.kundennummer">
      Kundennummer: {{ kundeDto?.kundennummer }}
    </div>

    <div class="hint">Ein gelöschter Kontakt kann nicht wiederhergestellt werden.</div>
  </div>

  <mat-divider></mat-divider>

  <div class="button-wrapper">

    <jf-button
      [variant]="'primary'"
      (doClick)="doDeleteKunde()"
      i18n
    >
      Löschen
    </jf-button>

    <jf-button
      [variant]="'ghost'"
      (doClick)="doCloseDialog()"
      i18n
    >
      Abbrechen
    </jf-button>
  </div>
</div>
