import {umsatzsteuerschluesselEntitiesAdapter} from '../adapters/umsatzsteuerschluessel-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {UmsatzsteuerschluesselEntitiesActions} from '../actions/umsatzsteuerschluessel-entities.actions';


export const initialUmsatzsteuerschluesselEntitiesState = umsatzsteuerschluesselEntitiesAdapter.getInitialState({
  isLoading: false,
});

export const umsatzsteuerschluesselEntitiesReducer = createReducer(
  initialUmsatzsteuerschluesselEntitiesState,

  on(UmsatzsteuerschluesselEntitiesActions.readUmsatzsteuerschluessel, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(UmsatzsteuerschluesselEntitiesActions.readUmsatzsteuerschluesselSuccess, (state, {
    betriebId,
    umsatzsteuerschluesselDtos
  }) => {
    return umsatzsteuerschluesselEntitiesAdapter.upsertMany(
      umsatzsteuerschluesselDtos.map(umsatzsteuerschluessel => ({
        ...umsatzsteuerschluessel,
        betriebId,
      })),
      {
        ...state,
        isLoading: false,
      },
    );
  }),
);
