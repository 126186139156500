import {Component} from '@angular/core';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject, take} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IntentActionService} from './services/intent-action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {ContextInfoService, WellKnownJustFarmingProperties} from './portal/context-info.service';
import {UiService} from './services/ui.service';
import {UmsatzsteuerService} from './openapi/fakturierung-openapi';
import {NavigationService} from '@adnova/jf-ng-components';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private readonly unsubscribe$ = new Subject<void>();

  runningInPortal$: Observable<boolean>;

  constructor(
    intentActionService: IntentActionService, // INFO: use for instantiation
    private portalMessageBroker: PortalMessageBroker,
    private oidcSecurityService: OidcSecurityService,
    private contextInfoService: ContextInfoService,
    private readonly tracker: MatomoTracker,
    private readonly uiService: UiService,
    private readonly navigationService: NavigationService,
    private readonly ustService: UmsatzsteuerService,
  ) {

    this.runningInPortal$ = this.portalMessageBroker.runningInPortal$
      .pipe(
        takeUntil(this.unsubscribe$),
      );

    this.contextInfoService.contextInfo$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(contextInfo => {
        let analytics = contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS);
        if (analytics === true) {
          this.tracker.setConsentGiven();
        } else {
          this.tracker.forgetConsentGiven();
        }
      });
  }

  doLogout(): void {
    this.oidcSecurityService.logoff().pipe(take(1)).subscribe();
  }
}
