import {createReducer, on} from '@ngrx/store';
import {LandEntitiesState} from '../states/land-entities.state';
import {landEntitiesAdapter} from '../adapters/land-entities.adapters';
import {LandEntitiesActions} from '../actions/land-entities.actions';


export const initialLandEntitiesState: LandEntitiesState = landEntitiesAdapter.getInitialState(
  {
    laenderLoaded: false,
  }
);

export const landEntitiesReducer = createReducer(
  initialLandEntitiesState,

  on(LandEntitiesActions.readLaenderSuccess, (state, {landDtos}) => {
    return landEntitiesAdapter.upsertMany(
      landDtos,
      {
        ...state,
        laenderLoaded: true,
      });
  }),
);
