import {createReducer, on} from '@ngrx/store';
import {UploadLogoDialogState} from '../states/upload-logo-dialog.state';
import {UploadLogoDialogActions} from '../actions/upload-logo-dialog.actions';


export const initialUploadLogoDialogState: UploadLogoDialogState = {
  isOpen: false,
};

export const uploadLogoDialogReducer = createReducer(
  initialUploadLogoDialogState,

  on(
    UploadLogoDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
    })
  ),

  on(
    UploadLogoDialogActions.close,
    () => ({
      ...initialUploadLogoDialogState,
    })
  ),
);
