import {createAction, props} from '@ngrx/store';
import {FakturierungsbelegFormComponentMode} from '../../types/fakturierungsbeleg-form-component-mode.type';
import {BelegDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {PositionAnzahlMode} from '../../interfaces/position-anzahl-modes.interface';
import {PositionAnzahl} from '../../interfaces/position-anzahl.interface';
import {
  PositionBetragssummeMode,
} from '../../interfaces/position-betragssumme-mode.interface';
import {PositionBetragssumme} from '../../interfaces/position-betragssumme.interface';
import {NachlaufzeileInput} from '../../modules/components/fakturierungsbelege-form/nachlaufzeilen/nachlaufzeile-list.component';
import {DateSelectingMode} from '@adnova/jf-ng-components';


export class FakturierungsbelegFormActions {

  public static reset = createAction(
    '[Fakturierungsbeleg Form] Reset this context.',
  );

  public static updateFormValidity = createAction(
    '[Fakturierungsbeleg Form] Update validity of the Beleg.',
  );

  public static updateFakturierungsbelegFormDto = createAction(
    '[Fakturierungsbeleg Form] Update the selected fakturierungsbeleg data.',
    props<{
      fakturierungsbelegDto: BelegDTO,
    }>(),
  );

  public static updateGesamtBruttoBetrag = createAction(
    '[Fakturierungsbeleg Form] Updates the gesamtBruttoBetrag.',
    props<{
      gesamtBruttoBetrag: number,
    }>(),
  );

  public static changeLeistungsempfaengerMode = createAction(
    '[Fakturierungsbeleg Form] Change Leistungsempfaenger Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Set Leistungsempfänger',
    props<{
      kundeId?: string,
    }>(),
  );

  public static changeVorlaufzeileMode = createAction(
    '[Fakturierungsbeleg Form] Change Vorlaufzeile Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static setVorlaufzeileText = createAction(
    '[Fakturierungsbeleg Form] Set Vorlaufzeile Text.',
    props<{
      text: string,
    }>(),
  );

  public static addNachlaufzeile = createAction(
    '[Fakturierungsbeleg Form] Add Nachlaufzeile',
    props<{
      nachlaufzeile: NachlaufzeileInput,
    }>(),
  );

  public static setNachlaufzeilen = createAction(
    '[Fakturierungsbeleg Form] Add Nachlaufzeilen',
    props<{
      nachlaufzeilen: NachlaufzeileInput[],
    }>(),
  );

  public static removeNachlaufzeile = createAction(
    '[Fakturierungsbeleg Form] Remove Nachlaufzeile',
    props<{
      nachlaufzeileId: string,
    }>(),
  );

  public static toggleNachlaufzeilenMode = createAction(
    '[Fakturierungsbeleg Form] Toggle Nachlaufzeilen Mode',
    props<{
      nachlaufzeileId: string,
    }>(),
  );

  public static selectLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Select Leistungsempfänger',
    props<{
      absenderId?: string,
    }>(),
  );

  public static addPosition = createAction(
    '[Fakturierungsbeleg Form] Add Position to the Fakturierungsbeleg Form.',
    props<{
      produktDto: ProduktDTO,
    }>(),
  );

  public static removePositionByNumber = createAction(
    '[Fakturierungsbeleg Form] Remove Position',
    props<{
      number: number,
    }>(),
  );

  public static changePositionAnzahlMode = createAction(
    '[Fakturierungsbeleg Form] Change Position Anzahl Mode.',
    props<{
      positionAnzahlMode: PositionAnzahlMode,
    }>(),
  );

  public static setPositionAnzahl = createAction(
    '[Fakturierungsbeleg Form] Set Position Anzahl.',
    props<{
      positionAnzahl: PositionAnzahl,
    }>(),
  );

  public static changePositionBetragssummeMode = createAction(
    '[Fakturierungsbeleg Form] Change Position Betragssumme Mode.',
    props<{
      positionBetragssummeMode: PositionBetragssummeMode,
    }>(),
  );

  public static setPositionBetragssummme = createAction(
    '[Fakturierungsbeleg Form] Set Position Betragssumme.',
    props<{
      positionBetragssumme: PositionBetragssumme,
    }>(),
  );

  public static changeAddProduktMode = createAction(
    '[Fakturierungsbeleg Form] Change Add-Produkt Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeRechnungsdatumMode = createAction(
    '[Fakturierungsbeleg Form] Change rechnungsdatum mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeLeistungsLieferDatumControlMode = createAction(
    '[Fakturierungsbeleg Form] Change leistungs-liefer-datum control mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeLeistungsLieferDatumDateMode = createAction(
    '[Fakturierungsbeleg Form] Change leistungs-liefer-datum date selecting mode.',
    props<{
      mode: DateSelectingMode,
    }>(),
  );

  public static setRechnungsdatum = createAction(
    '[Fakturierungsbeleg Form] Set Rechnungsdatum.',
    props<{
      rechnungsdatum: string,
    }>(),
  );

  // FIXME: When openAPI is updated/implemented by backend team, implement Reducers and Effects for these actions
  // Potential refactor : use only one single action to update the Store.
  public static setLeistungsLieferDatumSingle = createAction(
    '[Fakturierungsbeleg Form] Set leistungs-liefer-datum single date.',
    props<{
      leistungsLieferDatum: string,
    }>(),
  );

  public static setLeistungsLieferDatumRange = createAction(
    '[Fakturierungsbeleg Form] Set leistungs-liefer-datum range date.',
    props<{
      datumVon: string,
      datumBis: string,
    }>(),
  );

  public static getLogo = createAction(
    '[Fakturierungsbeleg Form] Get logo.',
    props<{ betriebId: string }>()
  );

  public static getLogoSuccess = createAction(
    '[Fakturierungsbeleg Form] Get logo successfully.',
    props<{ logoUrl: string | null }>()
  );

  public static getLogoFailure = createAction(
    '[Fakturierungsbeleg Form] Get logo failed.',
    props<{ error: any }>(),
  );

  public static saveLogo = createAction(
    '[Fakturierungsbeleg Form] Save logo.',
    props<{
      betriebId: string,
      logoUrl: string,
    }>(),
  );

  public static saveLogoSuccess = createAction(
    '[Fakturierungsbeleg Form] Save logo successfully.',
    props<{ logoUrl: string }>()
  );

  public static saveLogoFailure = createAction(
    '[Fakturierungsbeleg Form] Save logo failed.',
    props<{ error: any }>()
  );

  public static deleteLogo = createAction(
    '[Fakturierungsbeleg Form] Delete logo.',
    props<{ betriebId: string }>()
  );

  public static deleteLogoSuccess = createAction(
    '[Fakturierungsbeleg Form] Delete logo successfully.',
  );

  public static deleteLogoFailure = createAction(
    '[Fakturierungsbeleg Form] Delete logo failed.',
    props<{ error: any }>()
  );

  public static releaseLogoFromMemory = createAction(
    '[Fakturierungsbeleg Form] Release logo from browser memory.',
    props<{ logoUrl?: string }>()
  );
}
