import {Injectable} from "@angular/core";
import {mockPdf} from "../data/pdf.mock";

@Injectable({
  providedIn: 'root'
})
export class MockPdfService {
  // INFO: Erstellt einen Mock Blob der PDF-Datei, zum Mocken und für Unit Tests

  createMockPDFBlob(): Blob {
    return new Blob([mockPdf], { type: 'application/pdf' });
  }
}