<mat-dialog-content>
  <app-form
    [formInput]="formInputs"
    (cancelClicked)="closeDialogClick()"
  >
    <div *ngIf="!!produktDto?.id" class="header-content">
      Änderungen an bestehenden Produkten aktualisieren automatisch die zugehörigen<br>
      Rechnungsentwürfe und überschreiben das bisherige Produkt.
    </div>
    <div
      class="body-content">
      <app-produkt-form
        [produkt]="produktDto"
        [formControls]="formGroup"
        [einheiten]="einheiten"
        [berechnungsarten]="berechnungsarten"
        [umsatzsteuersaetze]="umsatzsteuersaetze"
        (produktChanged)="produktChanged($event)"
      ></app-produkt-form>
    </div>

    <div
      class='footer-content'>
      <jf-button
        (click)="doConfirmClicked()"
        [style]="'primary'"
        [disabled]="!checkFormComplete()"
      >
        {{ primaryButtonLabel }}
      </jf-button>
    </div>
  </app-form>
</mat-dialog-content>
