import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {UploadLogoDialogActions} from '../../../store/actions/upload-logo-dialog.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';
import {FakturierungsbelegFormActions} from '../../../store/actions/fakturierungsbeleg-form.actions';
import {FakturierungsbelegFormSelectors} from '../../../store/selectors/fakturierungsbeleg-form.selectors';
import {takeUntil} from 'rxjs/operators';
import {Subject, take} from 'rxjs';
import {InhaberEntitiesSelectors} from '@adnova/jf-ng-components';


enum ErrorMessageType {
  fileSize, fileType, fileCorruption
}

interface ErrorMessage {
  errorType: ErrorMessageType;
  message: string;
}

interface UploadErrors {
  fileTypeError: ErrorMessage;
  fileSizeError: ErrorMessage;
  fileCorruptionError: ErrorMessage;
}

@Component({
  selector: 'app-upload-logo-dialog',
  templateUrl: './upload-logo-dialog.component.html',
  styleUrls: ['./upload-logo-dialog.component.scss']
})
export class UploadLogoDialogComponent implements OnInit, OnDestroy {
  private readonly _unsubscribe$ = new Subject<void>();
  private _inhaberId!: string;
  private _allPossibleUploadErrors: UploadErrors = {
    fileTypeError: {
      errorType: ErrorMessageType.fileType,
      message: 'Fehler: Ungültiges Dateiformat. Nur PNG- und JPG-Dateien sind erlaubt.'
    },
    fileSizeError: {
      errorType: ErrorMessageType.fileSize,
      message: 'Fehler: Die Datei ist zu groß. Überprüfe die Datei auf Einhaltung der Vorgaben vor dem Hochladen.'
    },
    fileCorruptionError: {
      errorType: ErrorMessageType.fileCorruption,
      message: 'Fehler: Die Bilddatei ist beschädigt oder ungültig und kann nicht geladen werden.'
    }
  };
  @ViewChild('fileInput') private _fileInputElement!: ElementRef;
  private _store = inject(Store<AppState>);
  private _logger = inject(NGXLogger);
  private _snackbar = inject(MatSnackBar);

  isLogoUploadedInTheDialog: boolean = false;
  isDialogTouched = false;
  uploadedLogoInDialog: string | null = null;
  errorMessageToDisplay: string | null = null;

  ngOnInit(): void {
    window.addEventListener('dragover', this.preventGlobalDrag, false);
    window.addEventListener('drop', this.preventGlobalDrop, false);

    this._store.select(InhaberEntitiesSelectors.currentInhaber).pipe(takeUntil(this._unsubscribe$)).subscribe(inhaber => {
      if (!inhaber) return;
      this._inhaberId = inhaber.id;
    });

    this._store.select(FakturierungsbelegFormSelectors.logoUrl).pipe(
      take(1)
    ).subscribe(url => {
      if (url) {
        this.uploadedLogoInDialog = url;
        this.isLogoUploadedInTheDialog = true;
      }
    });
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    if (file) {
      this.processFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // TODO : we can add Additional visual feedback here
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // TODO : we Reset any visual changes made on drag over
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer && event.dataTransfer.files.length === 1) {
      this.processFile(event.dataTransfer.files[0]);
    }
  }

  processFile(file: File): void {
    const validTypes = ['image/png', 'image/jpeg'];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    // INFO : Validate file type
    if (!validTypes.includes(file.type)) {
      this.errorOccurred(this._allPossibleUploadErrors.fileTypeError);
      this._logger.warn('Invalid file type. Only PNG and JPG are allowed.');
      return;
    }

    // INFO : Validate file size
    if (file.size > maxFileSize) {
      this.errorOccurred(this._allPossibleUploadErrors.fileSizeError);
      this._logger.warn('File is too large. Maximum allowed file size is 5 MB.');
      return;
    }

    const objectURL = window.URL.createObjectURL(file);

    // INFO : Attempt to load the image to check for corruption
    const img = new Image();
    img.onload = () => {
      // INFO : Image loaded successfully, proceed to update state
      this.onDialogTouch();
      this.uploadedLogoInDialog = objectURL;
      this.isLogoUploadedInTheDialog = true;
      this.errorMessageToDisplay = null;
      this.resetFileInput();
    };

    img.onerror = () => {
      // INFO : Image failed to load, likely corrupted
      this.errorOccurred(this._allPossibleUploadErrors.fileCorruptionError);
      this._logger.warn('Image file is corrupted or invalid.');
      window.URL.revokeObjectURL(objectURL);
      this.resetFileInput();
    };

    // INFO :Start loading the image
    img.src = objectURL;
  }

  handleClickOfLogoViewer(): void {
    if (!this.isLogoUploadedInTheDialog) {
      this._fileInputElement.nativeElement.click();
    }
  }

  removeLogoInTheDialog(): void {
    this.onDialogTouch();
    this.releaseLogoFromMemoryIfNotSavedInStore();
    this.uploadedLogoInDialog = null;
    this.isLogoUploadedInTheDialog = false;
  }

  saveLogoOrDeleteItInBackend() {
    if (this.uploadedLogoInDialog) {
      this._store.dispatch(FakturierungsbelegFormActions.saveLogo({
        betriebId: this._inhaberId,
        logoUrl: this.uploadedLogoInDialog
      }));
    } else {
      this._store.dispatch(FakturierungsbelegFormActions.deleteLogo({
        betriebId: this._inhaberId,
      }));
    }

    this._store.dispatch(UploadLogoDialogActions.close());
  }

  closeDialog() {
    this.releaseLogoFromMemoryIfNotSavedInStore();
    this._store.dispatch(UploadLogoDialogActions.close());
  }

  private resetFileInput(): void {
    this._fileInputElement.nativeElement.value = '';
  }

  private preventGlobalDrag(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private preventGlobalDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private errorOccurred(error: ErrorMessage) {
    this.errorMessageToDisplay = error.message;
  }

  private onDialogTouch() {
    if (!this.isDialogTouched) {
      this.isDialogTouched = true;
    }
  }

  private releaseLogoFromMemoryIfNotSavedInStore() {
    this._store.select(FakturierungsbelegFormSelectors.logoUrl).pipe(take(1)).subscribe(logoUrlInStore => {
      if (this.uploadedLogoInDialog && this.uploadedLogoInDialog !== logoUrlInStore) {
        window.URL.revokeObjectURL(this.uploadedLogoInDialog!);
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('dragover', this.preventGlobalDrag, false);
    window.removeEventListener('drop', this.preventGlobalDrop, false);
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

}
