import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegTableState} from '../states/fakturierungsbeleg-table.state';
import {FakturierungsbelegTableActions} from '../actions/fakturierungsbeleg-table.actions';
import {FakturierungsbelegColumn} from 'src/app/modules/routes/fakturierungsbelege/fakturierungsbelege.component';
import {DirectionDTO} from '../../openapi/fakturierung-openapi';


export const initialFakturierungsbelegTableState: FakturierungsbelegTableState = {
  // FIXME: Passende Diplayed-Ids laden
  displayedIds: ['1', '2', '3'],
  tableSettings: {
    _key: 'fakturierungsbeleg-table-settings-2024-06-18',
    _columns: [
      {id: FakturierungsbelegColumn.Status, name: 'Status', isActive: true},
      {id: FakturierungsbelegColumn.Faelligkeit, name: 'Fälligkeit', isActive: true},
      {id: FakturierungsbelegColumn.Rechnungsnummer, name: 'Rechn.-Nr.', isActive: true},
      {id: FakturierungsbelegColumn.Kunde, name: 'Kunde', isActive: true},
      {id: FakturierungsbelegColumn.Rechnungsdatum, name: 'Rechn.-Dat.', isActive: true},
      {id: FakturierungsbelegColumn.Bruttobetrag, name: 'Brutto', isActive: true},
      {id: FakturierungsbelegColumn.Nettobetrag, name: 'Netto', isActive: true},
      {id: FakturierungsbelegColumn.Actions, name: '', isActive: true, sortable: false, lastCol: true},
    ],
    _sortDirs: ['desc'],
    _sortParams: [FakturierungsbelegColumn.Rechnungsnummer],
  },
  pageableDto: {
    size: 15,
    number: 0,
    direction: [DirectionDTO.Desc],
    property: [FakturierungsbelegColumn.Rechnungsnummer],
  },
  filterableDto: {
    filter: [''],
  },
  totalPages: 0,
};

export const fakturierungsbelegTableReducer = createReducer(
  initialFakturierungsbelegTableState,

  on(
    FakturierungsbelegTableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    FakturierungsbelegTableActions.changePage,
    (state, action) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        ...action.pageableDto,
      },
    })
  ),

  on (
    FakturierungsbelegTableActions.updateSearchValue,
    (state, action) => ({
      ...state,
      filterableDto: {
        filter: action.searchValue,
      }
    })
  ),

);
