import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableWrapperComponent} from './table-wrapper.component';
import {TableComponent} from './table/table.component';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMultiSortModule} from 'ngx-mat-multi-sort';
import {IconModule, TooltipModule} from '@adnova/jf-ng-components';


@NgModule({
  declarations: [
    TableWrapperComponent,
    TableComponent,
  ],
  exports: [
    TableWrapperComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatMultiSortModule,
    IconModule,
    TooltipModule,
  ]
})
export class TableWrapperModule {
}
