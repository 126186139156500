import {createAction} from '@ngrx/store';

export class BelegeEmptyStateDialogActions {

  public static open = createAction(
      '[Belege Empty State Dialog] Open Empty State Information Dialog.',
    );

    public static close = createAction(
      '[Belege Empty State Dialog] Close Empty State Information Dialog.',
    );
}
