import {createReducer, on} from '@ngrx/store';
import {ProduktDialogState} from '../states/produkt-dialog.state';
import {ProduktDialogActions} from '../actions/produkt-dialog.actions';
import {KundeDialogActions} from '../actions/kunde-dialog.actions';


export const initialProduktDialogState: ProduktDialogState = {
  isOpen: false,
  produkt: {},
  addToInvoice: undefined,
};

export const produktDialogReducer = createReducer(
  initialProduktDialogState,

  on(
    ProduktDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      produkt: action.produkt || {},
      addToInvoice: action.addToInvoice ?? undefined,
    })
  ),

  on(
    ProduktDialogActions.changeProdukt,
    (state, {produkt}) => {
      return {
        ...state,
        produkt,
      };
    },
  ),

  on(
    ProduktDialogActions.close,
    () => ({
      ...initialProduktDialogState,
    })
  ),

  on(
    ProduktDialogActions.assignNextProduktnummer,
    (state, {nummer}) => {
      return {
        ...state,
        produkt: {
          ...state.produkt,
          kundennummer: state.produkt.nummer === undefined ? nummer : state.produkt.nummer,
        },
      };
    },
  ),

);
