import {FakturierungsbelegEntitiesState} from '../states/fakturierungsbeleg-entities.state';
import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';
import {fakturierungsbelegeMock} from '../../mocks/data/fakturierungsbeleg-entities.mock';


export const initialFakturierungsbelegEntitiesState: FakturierungsbelegEntitiesState = fakturierungsbelegEntitiesAdapter.getInitialState({
  isLoading: false,
  entities: Object.fromEntries(
    /*
     * FIXME: Feld `value` wird den Mockdaten hinzugefügt.
     * Dieses Feld existiert aber eigentlich im DTO (noch?) nicht.
     * Hier müsste einmal geklärt werden, woher die Information tatsächlich kommen soll.
     */
    Object.entries(fakturierungsbelegeMock).map(([key, value]) => [key, { ...value, status: 'bezahlt' }])
  ),
  ids: Object.keys(fakturierungsbelegeMock),
  count: Object.keys(fakturierungsbelegeMock).length,
});

export const fakturierungsbelegEntitiesReducer = createReducer(
  initialFakturierungsbelegEntitiesState,

  on(
    FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess,
    (state, {fakturierungsbelegId}) => {
      return fakturierungsbelegEntitiesAdapter.removeOne(fakturierungsbelegId, {
        ...state,
      });
    }
  ),
);
