import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {AppState} from '../states/app.state';
import {FakturierungsbelegEntitiesSelectors} from './fakturierungsbeleg-entities.selectors';


export class FakturierungsbelegTableSelectors {


  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    tableSettings: (state: AppState) => state.fakturierungsbelegTable.tableSettings,
    displayedIds: (state: AppState) => state.fakturierungsbelegTable.displayedIds,
    pageableDto: (state: AppState) => state.fakturierungsbelegTable.pageableDto,
  };

  public static tableSettings = createSelector(
    this.select.tableSettings,
    identity,
  );

  public static displayedBelege = createSelector(
    this.select.displayedIds,
    FakturierungsbelegEntitiesSelectors.all,
    (selectedIds, fakturierungsbelege) =>
      FakturierungsbelegEntitiesSelectors.fakturierungsbelegeByIds(selectedIds).projector(fakturierungsbelege)
  );

  public static pageableDto = createSelector(
    this.select.pageableDto,
    identity,
  );

}
