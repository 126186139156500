import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';
import {concatMap, of, take, tap} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {catchError, filter, map} from 'rxjs/operators';
import {SentryActions} from '../actions/sentry.actions';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {Store} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {FakturierungsbelegEntitiesSelectors} from '../selectors/fakturierungsbeleg-entities.selectors';
import {BelegService} from '../../openapi/fakturierung-openapi';


@Injectable()
export class FakturierungsbelegEntitiesEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private belegService: BelegService,
    private store: Store<AppState>,
  ) {
  }

  /**
   * Effekt zum Laden eines Fakturierungsbelegs anhand der ID, sofern es noch nicht im Store vorhanden ist.
   */
  readonly loadFakturierungsbelegByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.loadFakturierungsbelegByIdIfAbsent),
      concatMap(action => this.store.select(FakturierungsbelegEntitiesSelectors.fakturierungsbelegById(action.fakturierungsbelegId)).pipe(
        take(1),
        map(fakturierungsbelegDto => ({action, fakturierungsbelegDto}))
      )),
      filter(data => !data.fakturierungsbelegDto),
      map(data => FakturierungsbelegEntitiesActions.loadFakturierungsbelegById({
        betriebId: data.action.betriebId,
        fakturierungsbelegId: data.action.fakturierungsbelegId,
      })),
    ),
  );

  /**
   * Effekt zum Laden eines Fakturierungsbelegs anhand der ID.
   */
  readonly loadFakturierungsbelegByID$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.loadFakturierungsbelegById),
      concatMap(({betriebId, fakturierungsbelegId}) => {
        return this.belegService.getBeleg(betriebId, fakturierungsbelegId).pipe(
          map((fakturierungsbelegDto) => {
            this.logger.debug(
              'read fakturierungsbeleg by ID succeeded. fakturierungsbelegDto:',
              fakturierungsbelegDto,
            );

            return FakturierungsbelegEntitiesActions.loadFakturierungsbelegByIdSuccess({fakturierungsbelegDto});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read fakturierungsbeleg by ID failed. fakturierungsbelegId:',
                fakturierungsbelegId,
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.loadFakturierungsbelegByIdFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  readonly loadFakturierungsbelegByIdFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.loadFakturierungsbelegByIdFailure),
      map(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Fakturierungsbelegs nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );

  /**
   * Effekt zum Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbeleg),
      concatMap(({
                   betriebId,
                   fakturierungsbelegId,
                 }) => {

        return this.belegService.deleteBeleg(betriebId, fakturierungsbelegId).pipe(
          map(() => {
            this.logger.debug(
              'delete fakturierungsbeleg succeeded. fakturierungsbelegId:',
              fakturierungsbelegId,
            );

            return FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess({
              fakturierungsbelegId,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'delete fakturierungsbeleg failed. fakturierungsbelegId:',
                fakturierungsbelegId,
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess),
      tap(() => {
        this.snackbar.open('Erfolgreich gelöscht', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure),
      map(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Fakturierungsbeleg nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          case 422 : {
            errorMsg = 'Fakturierungsbeleg kann nicht gelöscht werden.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Fakturierungsbeleg. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );
}
