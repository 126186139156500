import {AddPositionState} from '../states/add-position.state';
import {produktIdsMock} from '../../mocks/data/produkt-entities.mock';
import {createReducer, on} from '@ngrx/store';
import {AddPositionActions} from '../actions/add-position.actions';


export const initialAddPositionState: AddPositionState = {
  displayedIds: produktIdsMock,
  pageableDto: {
    size: 15,
    number: 0,
  },
  filterableDto: {
    filter: [],
  },
};

export const addPositionReducer = createReducer(
  initialAddPositionState,

  on(
    AddPositionActions.setDisplayedIds,
    (state, {produktDtos}) => ({
      ...state,
      displayedIds: produktDtos.map(produktDto => produktDto.id!),
    }),
  ),

  on(
    AddPositionActions.clearDisplayedProdukte,
    (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(
    AddPositionActions.updateFilter,
    (state, {filter}) => ({
      ...state,
      filterableDto: {
        ...state.filterableDto,
        filter,
      }
    }),
  ),

  on(
    AddPositionActions.loadMoreOptions,
    (state) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        number: (state.pageableDto.number || 0) + 1,
      }
    }),
  ),
);
