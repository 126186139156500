import {createReducer, on} from '@ngrx/store';
import {KundeTableActions} from '../actions/kunde-table.actions';
import {KundeEntitiesActions} from '../actions/kunde-entities.actions';
import {AbsenderColumn, KundeTableState} from '../states/kunde-table.state';
import {DirectionDTO} from '../../openapi/fakturierung-openapi';


export const initialKundeTableState: KundeTableState = {
  displayedIds: [],
  tableSettings: {
    _key: 'absender-table-settings-2024-06-18',
    _columns: [
      {
        id: AbsenderColumn.NameVorname,
        name: 'Nachname, Vorname',
        isActive: true,
        width: 15,
        overflow: 'ellipsis',
      }, {
        id: AbsenderColumn.Firma,
        name: 'Firma',
        isActive: true,
        width: 15,
        overflow: 'break',
      }, {
        id: AbsenderColumn.Adresse,
        name: 'Ort',
        isActive: true,
        width: 10,
        overflow: 'break',
      }, {
        id: AbsenderColumn.Actions,
        name: '',
        isActive: true,
        sortable: false,
        lastCol: true,
      },
    ],
    _sortDirs: [],
    _sortParams: [],
  },
  pageableDto: {
    size: 10,
    number: 0,
  },
  filterableDto: {
    filter: [''],
  },
  totalPages: 0,
  kundenLoaded: false,
};

export const kundeTableReducer = createReducer(
  initialKundeTableState,

  on(KundeTableActions.setDisplayedIds, (state, {kundeDtos}) => ({
      ...state,
      displayedIds: kundeDtos.map(kundeDto => kundeDto.id),
      kundenLoaded: true,
    }),
  ),

  on(KundeTableActions.clearDisplayedKunden, (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(KundeTableActions.changeTableSettings, (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
      // FIXME: Sort wird erst funktionieren wenn das Backend aktualisiert wurde.
      // pageableDto: {
      //   ...state.pageableDto,
      // https://gitlab.intern.landdata.de/land-data/fakturierung/fakturierung-service/-/issues/1
      // sort: {
      //   orders: [
      //     {
      //       direction: OrderDTODirectionEnum.Asc,
      //       ignoreCase: true,
      //       property: '',
      //     },
      //   ]
      // }
      // }
    })
  ),

  on(KundeTableActions.changePage, (state, action) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        ...action.pageableDto,
      },
    })
  ),

  on(KundeTableActions.updateSearchValue, (state, {filter}) => ({
      ...state,
      filterableDto: {
        filter,
      }
    })
  ),

  on(KundeEntitiesActions.countKundenPagesSuccess, (state, {totalPages}) => ({
    ...state,
    totalPages,
  })),
);
