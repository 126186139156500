import {createAction, props} from '@ngrx/store';
import {LandDTO} from '../../openapi/schluesseldaten-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class LandEntitiesActions {

  public static readLaender = createAction(
    '[Land Resolver] Read laender.',
  );

  public static readLaenderSuccess = createAction(
    '[Land Entities] Read laender successfully.',
    props<{
      landDtos: LandDTO[],
    }>()
  );

  public static readLaenderFailure = createAction(
    '[Land Entities] Read laender failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

}
