<div class="dialog-wrapper">

  <h1
    class="dialog-h1"
    i18n
  >
    Löschen
  </h1>

  <div
    class="beleg-data"
    i18n
  >
    <div *ngIf="fakturierungsbelegDto?.kundenbezeichnung">{{ fakturierungsbelegDto?.kundenbezeichnung }}</div>
    <div *ngIf="fakturierungsbelegDto?.kundennummer">Kundennummer: {{ fakturierungsbelegDto?.kundennummer }}</div>
    <div *ngIf="fakturierungsbelegDto?.belegnummer">Rechnungsnummer: {{ fakturierungsbelegDto?.belegnummer }}</div>
    <div *ngIf="fakturierungsbelegDto?.nettoBetragssumme">{{ fakturierungsbelegDto?.nettoBetragssumme | currency: 'EUR' }} Netto</div>
  </div>

  <mat-divider></mat-divider>

  <div class="button-wrapper">

    <jf-button
      [variant]="'primary'"
      (doClick)="deleteFakturierungsbelegClick()"
      i18n
    >
      Löschen
    </jf-button>

    <jf-button
      [variant]="'ghost'"
      (doClick)="closeDialogClick()"
      i18n
    >
      Abbrechen
    </jf-button>
  </div>
</div>
