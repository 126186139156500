import {createAction, props} from '@ngrx/store';
import {KundeDTO} from '../../openapi/fakturierung-openapi';
import {DeepPartial} from '../../types/deep-partial';


export class KundeDialogActions {

  private static readonly prefix = '[Kunde Dialog] ';

  public static open = createAction(
    this.prefix + 'Open kontakt dialog.',
    props<{
      kunde?: KundeDTO,
      addToInvoice?: boolean,
    }>(),
  );

  public static changeKunde = createAction(
    this.prefix + 'Change kunde.',
    props<{ kunde: DeepPartial<KundeDTO> }>(),
  );

  public static close = createAction(
    this.prefix + 'Close kontakt dialog.',
  );

  public static readLaender = createAction(
    this.prefix + 'Read laender.',
  );

  public static assignNextKundennummer = createAction(
    this.prefix + 'Assign next kundennummer.',
    props<{ nummer: number }>(),
  );

}
