import {TableSettings} from '../../interfaces/table-setting.interface';
import {FilterableDTO, PageableDTO} from '../../openapi/fakturierung-openapi';


export enum ProduktColumn {
  Nummer = 'nummer',
  Bezeichnung = 'produkt',
  Preis = 'preis',
  USt = 'ust',
  Actions = 'actions',
}

export interface ProduktTableState {
  displayedIds: string[];
  tableSettings: TableSettings;
  pageableDto: PageableDTO;
  filterableDto: FilterableDTO;
  totalPages: number;
}
