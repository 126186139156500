import {mengeneinheitEntitiesAdapter} from '../adapters/mengeneinheit-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {MengeneinheitEntitiesActions} from '../actions/mengeneinheit-entities.actions';


export const initialMengeneinheitEntitiesState = mengeneinheitEntitiesAdapter.getInitialState({
  isLoading: false,
});

export const mengeneinheitEntitiesReducer = createReducer(
  initialMengeneinheitEntitiesState,

  on(MengeneinheitEntitiesActions.readMengeneinheit, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(MengeneinheitEntitiesActions.readMengeneinheitSuccess, (state, {
    betriebId,
    mengeneinheitDtos
  }) => {
    return mengeneinheitEntitiesAdapter.upsertMany(
      mengeneinheitDtos.map(mengeneinheit => ({
        ...mengeneinheit,
        betriebId,
      })),
      {
        ...state,
        isLoading: false,
      },
    );
  }),
);
