import {umsatzsteuerschluesselEntitiesAdapter} from '../adapters/umsatzsteuerschluessel-entities.adapter';
import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';


export class UmsatzsteuerschluesselEntitiesSelectors {

  private static adapterSelectors = umsatzsteuerschluesselEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    umsatzsteuerschluesselEntities: (state: AppState) => state.umsatzsteuerschluesselEntities,
  };

  public static all = createSelector(
    this.select.umsatzsteuerschluesselEntities,
    this.adapterSelectors.selectAll,
  );

  public static umsatzsteuerschluesselByBetriebAndNummer = (betriebId: string, umsatzsteuerschluesselNummer: number) => createSelector(
    this.all,
    umsatzsteuerschluesselList => umsatzsteuerschluesselList.find(umsatzsteuerschluessel => {
      return umsatzsteuerschluessel.betriebId === betriebId && umsatzsteuerschluessel.nummer === umsatzsteuerschluesselNummer;
    }),
  );

  public static umsatzsteuerschluesselByBetrieb = (betriebId: string) => createSelector(
    this.all,
    umsatzsteuerschluesselList => umsatzsteuerschluesselList.filter(umsatzsteuerschluessel => {
      return umsatzsteuerschluessel.betriebId === betriebId;
    }),
  );

}
