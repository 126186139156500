import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginationComponent} from './pagination.component';
import {ButtonModule, TooltipModule} from '@adnova/jf-ng-components';


@NgModule({
  declarations: [
    PaginationComponent,
  ],
  exports: [
    PaginationComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
  ],
})
export class PaginationModule {
}
