import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';
import {BelegDTO} from '../../openapi/fakturierung-openapi';


export class FakturierungsbelegEntitiesActions {

  public static loadFakturierungsbelegById = createAction(
    '[Absender Entities] Load fakturierungsbeleg by ID.',
    props<{
      betriebId: string;
      fakturierungsbelegId: string;
    }>(),
  );

  public static loadFakturierungsbelegByIdIfAbsent = createAction(
    '[Fakturierungsbeleg Entities] Load fakturierungsbeleg by ID if absent.',
    props<{
      betriebId: string;
      fakturierungsbelegId: string;
    }>(),
  );

  public static loadFakturierungsbelegByIdSuccess = createAction(
    '[Fakturierungsbeleg Entities] Load fakturierungsbeleg successfully.',
    props<{
      fakturierungsbelegDto: BelegDTO;
    }>(),
  );

  public static loadFakturierungsbelegByIdFailure = createAction(
    '[Fakturierungsbeleg Entities] Load fakturierungsbeleg failed.',
    props<{
      error: any;
    }>(),
  );

  public static deleteFakturierungsbeleg = createAction(
    '[Fakturierungsbeleg Table] Delete single fakturierungsbeleg.',
    props<{
      betriebId: string,
      fakturierungsbelegId: string,
    }>(),
  );

  public static deleteFakturierungsbelegSuccess = createAction(
    '[Fakturierungsbeleg Entities] Delete single fakturierungsbeleg successfully.',
    props<{
      fakturierungsbelegId: string,
    }>(),
  );

  public static deleteFakturierungsbelegFailure = createAction(
    '[Fakturierungsbeleg Entities] Delete single fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countFakturierungsbelegeElementsSuccess = createAction(
    '[Fakturierungsbeleg Effects] Count list of fakturierungsbeleg elements successfully.',
    props<{
      totalElements: number,
    }>(),
  );

  public static countFakturierungsbelegePagesSuccess = createAction(
    '[Fakturierungsbeleg Effects] Count list of fakturierungsbeleg pages successfully.',
    props<{
      totalPages: number,
    }>(),
  );

}
