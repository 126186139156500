import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class ProduktDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.produktDialog.isOpen,
    produkt: (state: AppState) => state.produktDialog.produkt,
    addToInvoice: (state: AppState) => state.produktDialog.addToInvoice,
  };

  public static isOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static produkt = createSelector(
    this.select.produkt,
    identity,
  );

  public static addToInvoice = createSelector(
    this.select.addToInvoice,
    identity,
  );
}
