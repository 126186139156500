import {Component, inject} from '@angular/core';
import {TableRowButtonsModule} from '../../../../components/table/table-row-buttons/table-row-buttons.module';
import {TableRowButton} from '../../../../components/table/table-row-buttons/table-row-button.interface';
import {BelegDTO} from '../../../../../openapi/fakturierung-openapi';
import {ELEMENT_DATA} from '../../../../components/table/table-wrapper/table/table.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/states/app.state';
import {NGXLogger} from 'ngx-logger';
import {
  DeleteFakturierungsbelegDialogActions
} from '../../../../../store/actions/delete-fakturierungsbeleg-dialog.actions';
import {RouterActions} from '@adnova/jf-ng-components';
import {PdfDownloadService} from 'src/app/services/pdf-download.service';
import {MockPdfService} from 'src/app/mocks/services/mock-pdf.service';


@Component({
  standalone: true,
  selector: 'app-fakturierungsbeleg-actions.cell',
  templateUrl: './actions.cell.component.html',
  imports: [
    TableRowButtonsModule,
  ],
})
export class ActionsCellComponent {
  //INFO: Mock Service, delet before merge
  private pdfService = inject(MockPdfService);

  // INFO: Injections
  private _store = inject(Store<AppState>);
  private _logger = inject(NGXLogger);
  private _pdfDownloadService = inject(PdfDownloadService);

  protected data?: BelegDTO = inject(ELEMENT_DATA);

  // INFO: Properties
  private readonly editButtonId = 'edit-beleg-button';
  private readonly previewButtonId = 'preview-beleg-button';
  private readonly deleteButtonId = 'delete-beleg-button';
  private readonly downloadButtonId = 'download-beleg-button';

  protected editButton: TableRowButton = {
    id: this.editButtonId,
    tooltip: 'Bearbeiten',
    iconName: 'edit',
  };

  protected previewButton: TableRowButton = {
    id: this.previewButtonId,
    tooltip: 'Vorschau',
    iconName: 'eye',
  }

  protected deleteButton: TableRowButton = {
    id: this.deleteButtonId,
    tooltip: 'Löschen',
    iconName: 'basket',
  };

  moreOptionSecondaryButtons: TableRowButton[] = [
    {
      id: this.downloadButtonId,
      tooltip: 'Herunterladen',
      label: 'Herunterladen',
    },
  ];

  tableRowButtonIdClick(buttonId: string) {
    if (!this.data?.id) return;

    switch (buttonId) {
      case this.editButtonId:
        this._store.dispatch(RouterActions.navigateByUrl({
          url: 'fakturierungsbelege/inhaber/' + this.data!.betriebId + '/edit/fakturierungsbeleg/' + this.data?.id,
        }));
        break;
      case this.deleteButtonId:
        // FIXME: Passende InhaberId und FakruierungsbelegId verwenden
        this._store.dispatch(DeleteFakturierungsbelegDialogActions.open({
          fakturierungbelegId: this.data.id,
        }));
        break;
      case this.previewButtonId:
        this._store.dispatch(RouterActions.navigateByUrl({
          url: 'fakturierungsbelege/inhaber/' + this.data!.betriebId + '/preview/fakturierungsbeleg/' + this.data?.id,
        }));
        break;
      case this.downloadButtonId:
        // FIXME: richtige PDF-Datei herunterladen
        this._pdfDownloadService.downloadPDF(this.pdfService.createMockPDFBlob(), 'Fakturierungsbeleg_' + this.data.belegnummer + '.pdf', this.data.id);
        break;
      default:
        this._logger.warn('button with id not found: ', buttonId);
    }
  }

}
