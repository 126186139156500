import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {BelegDTO} from '../../openapi/fakturierung-openapi';


export class FakturierungsbelegEntitiesSelectors {

  private static adapterSelectors = fakturierungsbelegEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    belegEntities: (state: AppState) => state.fakturierungsbelegEntities,
    count: (state: AppState) => state.fakturierungsbelegEntities.count,
  };

  public static all = createSelector(
    this.select.belegEntities,
    this.adapterSelectors.selectAll,
  );

  /**
   * Selektor, der einen Fakturierungsbeleg anhand seiner ID zurückgibt.
   *
   * @param id
   */
  public static fakturierungsbelegById = (id: string) => createSelector(
    this.all,
    belegDtos => belegDtos.find(belegDto => {
      return belegDto.id === id;
    }),
  );

  /**
   * Selektor, der Fakturierungsbelege anhand einer Liste von IDs zurückgibt und dabei deren Reihenfolge beibehält.
   *
   * @param {string[]} fakturierungsbelegIds - Ein Array von BelegIDs.
   * Die Reihenfolge dieses Arrays bestimmt die Reihenfolge der zurückgegebenen Fakturierungsbelege.
   *
   * @returns {MemoizedSelector<AppState, BelegDTO[]>} Ein Selektor, der ein Array von Belegen zurückgibt, die den
   * angegebenen IDs entsprechen, in der in `belegIds` angegebenen Reihenfolge für den ausgewählten Inhaber.
   * Gibt ein leeres Array zurück, wenn keine übereinstimmenden Fakturierungsbelege gefunden wurden.
   */
  public static fakturierungsbelegeByIds = (fakturierungsbelegIds: string[]): MemoizedSelector<AppState, BelegDTO[]> => createSelector(
    this.all,
    belegDtos => {
      const belegMap = new Map(belegDtos.map(belegDto => [belegDto.id, belegDto]));
      return fakturierungsbelegIds
        .map(id => belegMap.get(id))
        .filter((belegDto): belegDto is BelegDTO => !!belegDto);
    }
  );

  public static fakturierungsbelegeByInhaberId = (betriebId: string) => createSelector(
    this.all,
    belegDtos => belegDtos.filter(belegDto => {
      return belegDto && belegDto.betriebId === betriebId;
    }),
  );

  public static count = createSelector(
    this.select.count,
    identity,
  );

}
