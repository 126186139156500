import {Injectable} from '@angular/core';
import {ProduktEntitiesEffects} from './produkt-entities.effects';
import {ProduktTableActions} from '../actions/produkt-table.actions';


@Injectable()
export class ProduktTableEffects {

  constructor(
    private produktEntitiesEffects: ProduktEntitiesEffects,
  ) {
  }

  readonly readProdukte$ = this.produktEntitiesEffects.readProdukte$(
    ProduktTableActions.readProdukte,
    ProduktTableActions.setDisplayedIds,
    ProduktTableActions.clearDisplayedProdukte,
  );

}
